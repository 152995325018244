import React from 'react'
import './Policy.css'
const Policy = () => {
  return (
    <div className="policy">
    <h1>Chính sách bảo mật</h1>
    <p>Thông tin cung cấp trên trang web này chỉ dành cho mục đích tìm hiểu thông tin và giáo dục chung. Vui lòng đọc và xem lại các Điều khoản Sử dụng dưới đây một cách cẩn thận trước khi truy cập hoặc sử dụng trang web. Bằng cách truy cập hoặc sử dụng trang web này, người dùng đã xác nhận rằng đọc, hiểu và đồng ý với Thỏa thuận Điều khoản Sử dụng. Nếu không đồng ý với Điều khoản sử dụng, người dùng không thể truy cập hoặc sử dụng trang web.</p>
    <ol>
        <li>Sử dụng trang web</li>
        <p>Thông tin cung cấp trên trang web này chỉ dành cho mục đích tìm hiểu thông tin và giáo dục chung. Việc truy cập và sử dụng thông tin có trong trang web phải tuân theo Thỏa thuận Điều khoản Sử dụng này. Bằng cách truy cập và sử dụng trang web, người dùng chấp nhận hoàn toàn Thỏa thuận Điều khoản Sử dụng này.</p>
        <li>Nội dung</li>
        <p>Chúng tôi sẽ nỗ lực để đưa đến người dùng những thông tin chính xác và cập nhật nhất trên trang web này nhưng không tuyên bố đảm bảo về tính chính xác và đầy đủ hoàn toàn của thông tin. Người dùng truy cập trang web tự chịu rủi ro về nội dung của trang. Chúng tôi từ chối mọi bảo đảm, bất kể là rõ ràng hay ngụ ý, bao gồm cả việc bảo đảm về khả năng tiêu thụ hoặc tính phù hợp cho một mục đích cụ thể. Chúng tôi và những bên liên quan đến việc tạo, sản xuất hoặc cung cấp trang web này sẽ không chịu trách nhiệm pháp lý đối với bất kỳ thiệt hại nào, bao gồm tất cả các thiệt hại trực tiếp hay gián tiếp phát sinh từ việc truy cập, sử dụng hoặc không thể sử dụng trang web.</p>
        <li>Các trang web, liên kết của bên thứ ba</li>
        <p>Trang web này có thể chứa các tham chiếu hoặc liên kết đến các trang web khác do các bên thứ ba duy trì mà chúng tôi không có quyền kiểm soát. Các liên kết được cung cấp với mục đích tham khảo. Tương tự trang của chúng tôi, trang web này có thể được truy cập từ các liên kết của bên thứ ba mà chúng tôi không có quyền kiểm soát. Chúng tôi không đảm bảo hoặc tuyên bố dưới bất kỳ hình thức nào về tính chính xác, hoặc tính hoàn chỉnh của bất kỳ thông tin nào có trong các trang web của bên thứ ba, bao gồm bất kỳ thông tin nào trên nền tảng ứng dụng di động hoặc phương tiện truyền thông xã hội của bên thứ ba. Việc bao gồm bất kỳ liên kết của bên thứ ba nào trên trang web này đều không có ngụ ý xác nhận hoặc khuyến nghị của chúng tôi. Đồng thời cũng không ngụ ý mối quan hệ của chúng tôi và bất kỳ bên thứ ba nào. Việc người dùng sử dụng bất kỳ trang web hoặc nền tảng của bên thứ ba đều do người dùng tự chịu rủi ro và sẽ chịu sự điều chỉnh của các điều khoản và chính sách của bên thứ ba.</p>
        <li>Bản quyền</li>
        <p>Toàn bộ nội dung của trang web này được bảo vệ bản quyền. Nội dung của các trang web không được sao chép ngoài mục đích tham khảo cá nhân phi thương mại với tất cả các thông báo bản quyền hoặc độc quyền khác được giữ lại, sau đó không được sao chép, tái tạo hoặc phân phối lại.</p>
    </ol>
    </div>
  )
}

export default Policy