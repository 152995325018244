import React from 'react'
import './Item.css'
import { Link } from 'react-router-dom'
const Item = (props) => {
  return (
    <div className="item">
        <Link to={{
          pathname: '/product',
          search: `?_id=${props.id}`
        }}>
          <img src={props.image} onClick={window.scrollTo(0, 0)} alt=''/>
        </Link>
        <p className='item-name'>{props.name}</p>
        <div className="item-prices">
            <div className="item-price-new">
                {props.new_price.toLocaleString('en-US')}đ
            </div>
            <div className="item-price-old">
                ${props.old_price.toLocaleString('en-US')}đ
            </div>
        </div>
    </div>
  )
}

export default Item