import React, { createContext, useState, useEffect } from 'react'
import all_product from '../Components/Assets/all_product'
const isNotEmptyObject = (value) => {
    return typeof value === 'object' && value !== null 
    && !Array.isArray(value) 
    && Object.keys(value).length !== 0;
}

export const existObjects = (obj) => {
        for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            if (isNotEmptyObject(obj[key])) {
            return true; 
            }
        }
        }
    return false; 
}
export const ShopContext = createContext(null)
export const getDefaultCart = (p) => {
    let cart = {}
    for(let i = 0; i < p.length; i++)
    {
        let sizes = p[i].size
        for(let j = 0; j < sizes.length; j++)
        {
            cart[`${sizes[j]._id}`] = {}
        }
    }
       
    return cart
}

const ShopContextProvider = ({children}) => {
    const [data, setData] = useState(null)
    const [favorite, setFavorite] = useState(Array(0))
    const [cartAmount, setCartAmount] = useState(0)
    const [cartReview, setCartReview] = useState(Array(0))
    const [cartItems, setCartItems] = useState(Array(0))
    const [totalItem, setTotalItem] = useState(0)
    const [duplicatedField, setDuplicatedField] = useState('Tên người dùng')
    const [fieldValue, setFieldValue] = useState('')
    const getCurrentUserComments = (currentUser) => {
        if (currentUser && data) {
            const commentsByUsers = data.data.products.flatMap(product => {
                if (product.comments.some(comment => comment.userName === currentUser.userName)) {
                    return product.comments
                        .filter(comment => comment.userName === currentUser.userName)
                        .map(comment => ({ ...comment, productId: product._id, image: product.imageCover, title: product.name, category: product.classify}));
                }
                return [];
            });
            return commentsByUsers;
        }
        return []; 
    };
    const loginCheck = async () => {
        const sessionToken = localStorage.getItem('session-token')
        if(!sessionToken)
        {
          return
        }
        await fetch('https://qhn-api.onrender.com/user/profile', {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${sessionToken}`
            }
        })
    }
    
    useEffect(() => {
        (async () => {
            const promise = await fetch('https://qhn-api.onrender.com/product');
            const response = await promise.json();
            setData(response);
        })();
    }, []);

    const getItemToCart = async (item, image, name) => {
        const token = localStorage.getItem('session-token')
        if(!token)
            return
        setCartAmount(prev => {
            prev += (item.price * item.quantity)
            return prev
        })
        setTotalItem(prev => {
            prev += item.quantity
            return prev
        })
        setCartReview(prev => [...prev, {...item, image: image, name: name}])
        setCartItems(prev => {
            if (!Array.isArray(prev)) {
                prev = [];
            }
            return [...prev, item]
        })
        await fetch('https://qhn-api.onrender.com/user/cart', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(item)
        }).then(res => res.json())
    }
    const removeFromCart = async (index, price, quantity) => {
        const token = localStorage.getItem('session-token')
        if(!localStorage.getItem('session-token'))
            return
        setCartAmount(prev => {
            prev -= (price * quantity)
            return prev
        })
        setCartReview(prev => {
          if(!Array.isArray(prev))
            return Array(0)
          const newCart = [...prev]
          newCart.splice(index, 1)
          return newCart
        })
        setCartItems(prev => {
            if(!Array.isArray(prev))
                return Array(0)
            const newCart = [...prev]
            newCart.splice(index, 1)
            return newCart
        })
        setTotalItem(prev => {
            prev -= quantity
            return prev
        })
        await fetch('https://qhn-api.onrender.com/user/updateCartQuan', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                cartIndex: index,
                quantity: 0
            })
        })
    }
    const changeQuantity = async (index, price, quantity) => {
        const token = localStorage.getItem('session-token')
        if(!localStorage.getItem('session-token'))
            return
        setCartAmount(price * quantity)
        setCartReview(prev => {
            prev[index].quantity = quantity;
            return prev
        })
        setCartItems(prev => {
            prev[index].quantity = quantity;
            return prev
        })
        setTotalItem(quantity)
        await fetch('https://qhn-api.onrender.com/user/updateCartQuan', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                cartIndex: index,
                quantity: quantity
            })
        })
    }
    const clearCart = async () => {
        const token = localStorage.getItem('session-token')
        if(!localStorage.getItem('session-token'))
            return
        setCartAmount(0)
        setCartItems(null)
        setCartReview(null)
        setTotalItem(0)
        await fetch('https://qhn-api.onrender.com/user/cart', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
    }
    const clearCurrentCart = () => {
        setCartAmount(0)
        setCartItems(null)
        setCartReview(null)
        setTotalItem(0)
    }
    const setCart = async () => {
        const token = localStorage.getItem('session-token')
        if(!token)
            return
        let cart = []
        let cartR = []
        let total = 0
        await fetch('https://qhn-api.onrender.com/user/cart', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res => res.json()).then(res => {
            setCartAmount(res.data.cart.totalCart)
            res.data.cart.products.forEach(i => {
                const data = {
                    productId: i.product._id,
                    price: i.price,
                    quantity: i.quantity,
                    size: i.size
                }
                cartR.push({...data, image: i.product.imageCover, name: i.product.name})
                cart.push(data)
                total += i.quantity
            })
        })
        setTotalItem(total)
        setCartItems(cart)
        setCartReview(cartR)
    }
    
    useEffect(() => {
        const getFavorite = async () => {
            const token = localStorage.getItem('session-token')
            if(!token)
                return
            let favourite = []
            await fetch('https://qhn-api.onrender.com/user/favorite',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(res => res.json())
            .then(res => {
                res.data.favorite.forEach(i => favorite.push(i.products._id))
            })
            setFavorite(favourite)
        }
        loginCheck()
        setCart()
        getFavorite()
    }, [])
    const contextValue = {
        data, setData,
        duplicatedField, setDuplicatedField,
        fieldValue, setFieldValue,
        all_product, cartAmount, cartReview,
        cartItems, totalItem,
        changeQuantity, clearCart, getCurrentUserComments,
        removeFromCart, getItemToCart, setCart,
        clearCurrentCart, favorite, setFavorite
    }
    return(
        <ShopContext.Provider value={contextValue}>
            {children}
        </ShopContext.Provider>
    )
}


export default ShopContextProvider