import React, {useRef, useState, useContext} from 'react'
import Google from '../../Components/SocialAuth/Google'
import Facebook from '../../Components/SocialAuth/Facebook'
import { ShopContext } from '../../Context/ShopContext'
import './Login.css'
import { Link } from 'react-router-dom'
const Login = () => {
  const {
    getCurrentUserComments, setCart} = useContext(ShopContext)
  const [loading, setLoading] = useState(false);
  const username = useRef()
  const password = useRef()
  const login = async (e) => {
    e.preventDefault();
    setLoading(true)
    const data = {
      userName: username.current.value,
      password: password.current.value
    }
    await fetch('https://qhn-api.onrender.com/user/login', {
      method: 'POST',
      headers: {
        "Content-Type" : "application/json",
      },
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then((res) => {
        if(res.status === 'success')
        {
          localStorage.setItem('session-token', res.token)
          return fetch('https://qhn-api.onrender.com/user/profile', {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${res.token}`
            }
        })
        }
        else alert('Tài khoản hoặc mật khẩu của bạn không đúng')
        return 
    })
    .then(res => {
      if(res)
      return res.json()
    return
    })
    .then(res => {
      if(res)
      {
        setCart()
        localStorage.setItem('user-info', JSON.stringify(res.data[0]))
        localStorage.setItem('user-comments', JSON.stringify(getCurrentUserComments(res.data[0])))
        return window.location.href = '/'
      }
      return window.location.href = '/login'
    })
    .catch(e => console.log(e)).finally(() => {
        setLoading(false)
    })
  }
  return (
    <div className='login'>
      <div className="login-container">
        <h1>Đăng nhập</h1>
        <form className="login-fields" onSubmit={login}>
          <label htmlFor='username'>
          <input name='username' type="text" placeholder='Tên người dùng' ref={username} autoComplete='username' required/>
          </label>
          <label htmlFor='password'>
          <input name='password' type="password" placeholder='Mật khẩu' ref={password}  autoComplete="current-password" required/>
          </label>
          <button type='submit' disabled={loading}>
            <span>Đăng nhập</span>
            {loading && <div className='login-loader'></div>}
          </button>
        </form>
        <hr/>
        <div className='login-row'>
          <Facebook/>
          <Google/>
        </div>
        <p className='login-login'>
            <span className="login-alreadyhasaccount">Bạn chưa có tài khoản? 
            <Link style={{textDecoration: 'none'}} to='/signup'>
            <span> Đăng ký</span>
            </Link>
            </span>
            <Link style={{textDecoration: 'none'}} to='/forgotpassword/sendemail'>
            <span className="login-forgotpassword">Quên mật khẩu?</span>
            </Link>
        </p>
      </div>
    </div>
  )
}

export default Login