import React, { useEffect, useState } from 'react'
import './PurchaseHistory.css'
import { Bar, Line, Pie } from 'react-chartjs-2';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
const PurchaseHistory = () => {
  Chart.register(CategoryScale);
  const colors = {
    "S": { backgroundColor: "#a11a35" },
    "M": { backgroundColor: "#d92518" },
    "L": { backgroundColor: "#637875"},
    "XX": { backgroundColor: "#ffda9e" },
    "XXL": { backgroundColor: "blue"},
  };
  const formatDate = (date) => {
    // Ensure the input is a Date object
    if (!(date instanceof Date)) {
        throw new Error('Input must be a Date object');
    }
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;
    return `${formattedDay}/${formattedMonth}`;
}

  const transformData = (data) => {
    data.sort((a, b) => {
      const dateA = new Date(a.orderDate.split('/').reverse().join('-'));
      const dateB = new Date(b.orderDate.split('/').reverse().join('-'));
      return dateA - dateB;
  });

  const uniqueOrderDates = Array.from(new Set(data.map(item => item.orderDate)));
  const groupedData = data.reduce((acc, { orderDate, size, productQuantity }) => {
      if (!acc[size]) {
          acc[size] = uniqueOrderDates.map(date => ({ orderDate: date, productQuantity: 0 }));
      }
      const dateEntry = acc[size].find(entry => entry.orderDate === orderDate);
      dateEntry.productQuantity = productQuantity;
      return acc;
  }, {});
  
    const result = Object.keys(groupedData).map(size => ({
    label: size,
    borderColor: colors[size].backgroundColor,
    backgroundColor: colors[size].backgroundColor,
    borderWidth: 1,
    data: groupedData[size].map(entry => entry.productQuantity)
    }));
    return result
  }
  const [productCount, setProductCount] = useState()
  const [transactionCount, setTransactionCount] = useState()
  const [sizePercentage, setSizePercentage] = useState()
  const [totalQuantity, setTotalQuantity] = useState()
  const [maxQuantity, setMaxQuantity] = useState()
  
  useEffect(() => {
    const fetchMaxQuantity = async () => {
      const token = localStorage.getItem('session-token')
      if(!token)
        return
      await fetch('https://qhn-api.onrender.com/user/orderPlaced', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json()).then(res => {
        const productQuantities = new Map();
        res.data.forEach(order => {
            const orderDate = new Date(order.orderDate).toLocaleDateString('en-GB');
            const totalQuantity = order.products.reduce((total, product) => total + product.quantity, 0);
            if (productQuantities.has(orderDate)) {
                productQuantities.set(orderDate, productQuantities.get(orderDate) + totalQuantity);
            } else {
                productQuantities.set(orderDate, totalQuantity);
            }
        });
        const result = [];
        productQuantities.forEach((value, key) => {
            result.push({ orderDate: key, productQuantity: value });
        });
        const total = res.data.reduce((acc, order) => {
          return acc + order.products.reduce((acc, product) => {
            return acc + product.quantity;
          }, 0);
        }, 0);
        const sizeQuantityArray = res.data.reduce((acc, order) => {
          order.products.forEach(product => {
            const { size, quantity } = product;
            const existingItemIndex = acc.findIndex(item => item.size === size);
            if (existingItemIndex !== -1) {
              acc[existingItemIndex].quantity += quantity;
            } else {
              acc.push({ size, quantity });
            }
          });
          return acc;
        }, []);
        setTotalQuantity(total)
        setMaxQuantity(result.filter(i => i.productQuantity === Math.max(...result.map(i => i.productQuantity)))[0])
        setSizePercentage({
          labels: sizeQuantityArray.map(i => i.size),
          datasets: [{
            label: '',
            data: sizeQuantityArray.map(i => i.quantity),
            backgroundColor: sizeQuantityArray.map(i => colors[i.size].backgroundColor),
            borderColor: '#696969',
            borderWidth: 1
          }]
        })
      })
    }
    const fetchProductCount = async () => {
      const token = localStorage.getItem('session-token')
      if(!token)
        return
      await fetch('https://qhn-api.onrender.com/user/orderPlaced', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json()).then(res => {
        const productQuantitiesByDateAndSize = new Map();
        res.data.forEach(order => {
            const orderDate = new Date(order.orderDate).toLocaleDateString('en-US');
            order.products.forEach(product => {
                const key = `${orderDate}-${product.size}`;
                if (productQuantitiesByDateAndSize.has(key)) {
                    productQuantitiesByDateAndSize.set(key, productQuantitiesByDateAndSize.get(key) + product.quantity);
                } else {
                    productQuantitiesByDateAndSize.set(key, product.quantity);
                }
            });
        });
        const result = [];
        productQuantitiesByDateAndSize.forEach((value, key) => {
            const [orderDate, size] = key.split('-');
            result.push({ orderDate, size, productQuantity: value });
        });
        setProductCount({
          labels: [...new Set(result.map(item => formatDate(new Date(item.orderDate))))],
          datasets: transformData(result)
        })
       
      })
    }
    const fetchTransactionCount = async () => {
      const token = localStorage.getItem('session-token')
      if(!token)
        return
      await fetch('https://qhn-api.onrender.com/user/orderPlaced', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json()).then(res => {
        const orderCounts = [];
        res.data.forEach(order => {
          const orderDate = new Date(order.orderDate).toLocaleDateString('en-US');
          const existingOrderIndex = orderCounts.findIndex(item => item.date === orderDate);
          if (existingOrderIndex !== -1) {
            orderCounts[existingOrderIndex].count++;
          } else {
            orderCounts.push({ date: orderDate, count: 1 });
          }
        });
        setTransactionCount({
          labels: orderCounts.map(order => formatDate(new Date(order.date))),
          datasets: [{
            label: '',
            backgroundColor: 'blue',
            barThickness: 20,
            data: orderCounts.map(order => order.count)
          }]
        })
      })
    }
    fetchProductCount()
    fetchTransactionCount()
    fetchMaxQuantity()
  }, [])
  return (
    <div className='purchasehistory'>
      <h1>Lịch sử giao dịch</h1>
      <div className="purchasehistory-chart-wrapper">
      <div className='purchasehistory-chart'>
      {
        productCount && 
        <Line
        data={productCount}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          stacked: false,
          scales: {
            y: {
              type: 'linear',
              display: true,
              position: 'left',
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              grid: {
                drawOnChartArea: false, 
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: "SỐ SẢN PHẨM THEO NGÀY"
            },
            legend: {
              display:  true
            }
          }
        }}
        ></Line>
      }
      </div>
      <div className='purchasehistory-chart'>
      {
        transactionCount && 
        <Bar
        data={transactionCount}
        options={{
          scales: {
            xAxes: [{
                barThickness: 2,  
                maxBarThickness: 2 
            }]
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: "LỊCH SỬ GIAO DỊCH"
            },
            legend: {
              display:  false
            }
          }
        }}
        ></Bar>
      }
      </div>
      </div>
      <div className='purchasehistory-record'>
        <div className='purchasehistory-record-box'>
          <span className='purchasehistory-record-title'>Số sản phẩm cao nhất / giao dịch</span>
          <div className='purchasehistory-record-number'>
            <div className='purchasehistory-record-number-context'>
            <span>
              {
                maxQuantity ? maxQuantity.productQuantity : 0
              }
            </span>
            <p>
              {
                maxQuantity ? maxQuantity.orderDate : null
              }
            </p>
            </div>
          </div>
        </div>

        <div className='purchasehistory-record-box'>
          <span className='purchasehistory-record-title'>Tổng sản phẩm đã mua</span>
          <div className='purchasehistory-record-number'>
            <div className='purchasehistory-record-number-context'>
            <span>
              {
                totalQuantity ? totalQuantity : 0
              }
            </span>
            <p>
              {
                new Date(Date.now()).toLocaleDateString('en-GB')
              }
            </p>
            </div>
          </div>
        </div>
        <div className='purchasehistory-record-chart'>
          {
            sizePercentage &&  <Pie
            data={sizePercentage}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
              
              }
            }}
            ></Pie>
          }
        </div>
      </div>
    </div>
  )
}

export default PurchaseHistory