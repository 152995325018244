import React, {useRef} from 'react'
import './SendEmail.css'
const SendEmail = () => {
  const email = useRef()
  const sendEmail = async(e) => {
    e.preventDefault()
    const data = {
      email: email.current.value
    }
    await fetch('https://qhn-api.onrender.com/user/forgotPassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(res => res.json())
    .then(res => {
      if(res.status === 'success')
      {
        alert(`QHN Shop đã gửi mã xác nhận đến ${email.current.value}. Hãy kiểm tra hòm thư của bạn`)
        window.location.href = '/forgotpassword/changepassword'
      }
      else if(res.status === 'fail')
        alert(`Không tồn tại email ${email.current.value}.`)
        email.current.value = ''
    })
  }
  return (
    <div className="sendemail-container">
      <h1>Lấy lại mật khẩu</h1>
      <form className="sendemail-fields" onSubmit={sendEmail}>
        <label htmlFor='email'>
        <input name='email' type="email" placeholder='Email của bạn' ref={email} autoComplete="new-password" required/>
        </label>
        <button type='submit'>
          <span>Xác nhận</span>
        </button>
      </form>
    </div>
  )
}

export default SendEmail