import React, {useRef, useState} from 'react'
import './ChangePassword.css'
const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const password = useRef()
  const passwordConfirm = useRef()
  const token = useRef()
  const slide = (e) => {
    e.preventDefault();
    document.querySelector('.changepassword-wrapper div:first-child span').style.display = 'none'
    document.querySelector('.changepassword-wrapper').style.transform = 'translateX(calc(-100% - 2dvw))'
  }
  const changepassword = async (e) => {
    e.preventDefault();
    setLoading(true)
    const data = {
      token: token.current.value,
      password: password.current.value,
      passwordConfirm: passwordConfirm.current.value,
    }
    if(passwordConfirm.current.value !== password.current.value)
    {
      document.querySelector('.changepassword-wrapper div:nth-child(2) span:nth-of-type(2)').style.display = 'block'
      document.querySelector('.changepassword-wrapper div:nth-child(2) span:nth-of-type(2)').innerText = 'Xác minh mật khẩu không đúng'
      password.current.value = ''
      passwordConfirm.current.value = ''
      password.current.focus()
      return
    }
    if(password.current.value.length < 10)
    {
      document.querySelector('.changepassword-wrapper div:nth-child(2) span:first-of-type').style.display = 'block'
      document.querySelector('.changepassword-wrapper div:nth-child(2) span:first-of-type').innerText = 'Mật khẩu chứa tối thiểu 10 ký tự'
      password.current.value = ''
      passwordConfirm.current.value = ''
      password.current.focus()
      return
    }
    document.querySelector('.changepassword-loader').style.display = 'block'
    await fetch('https://qhn-api.onrender.com/user/resetPassword', {
      method: 'PATCH',
      headers: {
        "Content-Type" : "application/json",
      },
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(res => {
      document.querySelector('.changepassword-loader').style.display = 'none'
      if(res.message === 'Code is invalid or has expired')
      {
        document.querySelector('.changepassword-wrapper div:first-child span').style.display = 'block'
        document.querySelector('.changepassword-wrapper div:first-child span').innerText = 'Mã xác nhận của bạn không đúng hoặc đã hết hạn'
        document.querySelector('.changepassword-wrapper').style.transform = 'translateX(0)'
      }
      if(res.status === 'success')
      {
        alert('Đổi mật khẩu thành công!')
        window.location.href = '/login'
      }
    })
  }
  return (
      <div className="changepassword-container">
        <form className="changepassword-fields" onSubmit={changepassword}>
          <div className="changepassword-wrapper">
          <div>
          <h2>Nhập mã của bạn </h2>
          <span></span>
          <label htmlFor='token'>
          <input name='token' type='text' ref={token} placeholder='Nhập mã của bạn ở đây' autoComplete="new-password" onInput={() => setIsFilled(token.current.value !== '')}/>
          </label>
          <button onClick={(e) => slide(e)} disabled={!isFilled} className={isFilled ? '' : 'changepassword-disabled'}>
            Tiếp theo
          </button>
          </div>
          <div>
          <h2>Đổi mật khẩu </h2>
          <span></span>
          <label htmlFor='password'>
          <input name='password' type="password" placeholder='Nhập mật khẩu mới của bạn' ref={password} autoComplete="new-password" required/>
          </label>
          <span></span>
          <label htmlFor='passwordConfirm'>
          <input name='passwordConfirm' type="password" placeholder='Xác minh mật khẩu' ref={passwordConfirm} autoComplete="new-password" required/>
          </label>
          <button>
            Xác nhận
            {
              loading && <div className='changepassword-loader'></div>
            }
          </button>
          </div>
          </div>
        </form>
      </div>
  )
}

export default ChangePassword