import React, {useContext} from 'react'
import { ShopContext } from '../../Context/ShopContext'
import { signInWithPopup } from 'firebase/auth'
import { auth, provider } from '../../FirebaseConfig'
import './SocialAuth.css'
import facebook_logo from '../Assets/facebook_icon.png'
const Facebook = () => {
  const {getCurrentUserComments, setDuplicatedField, setFieldValue} = useContext(ShopContext)
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const changeInfo = async (image, userName, token) => {
    await fetch('https://qhn-api.onrender.com/user/editProfile', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({photo: image, userName: userName})
    })
  }
  const getUserInfo = async (token, image) => {
    console.log(image)
    return fetch('https://qhn-api.onrender.com/user/profile', {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${token}`
            }}).then(res => res.json()).then(res => {
              if(!res)
                return window.location.href = '/login'
              else
                {
                  localStorage.setItem('user-info', JSON.stringify({...res.data[0], 'photo': image, 'isSocialAccount': true}))
                  localStorage.setItem('user-comments', JSON.stringify(getCurrentUserComments(res.data[0])))
                  return window.location.href = '/'
                }
            })
  }
  const login = async (username, password, image) => {
    return fetch('https://qhn-api.onrender.com/user/login', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: username,
        password: password
      })
    }).then(res => res.json())
    .then(res => {
      if(res.status === 'success')
      {
          localStorage.setItem('session-token', res.token)
          changeInfo(image, username, res.token)
          getUserInfo(res.token, image)
      };
    })
  }

  const addFacebookAccount = async (res) => {
    const userName = res.displayName
    const password = '1234567890'
    const email = res.email
    const image = res.photoUrl
    const data = {
      userName: userName,
      email: email,
      password: password,
      passwordConfirm: password
    }
    await fetch('https://qhn-api.onrender.com/user/signup', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(res => {
      if(res.message.includes('Duplicated field value'))
      {
        const duplicatedField = res.message.replace('Duplicated field value ', '')
        if(!isValidEmail(duplicatedField))
        {
          document.querySelector('.validate').style.display = 'flex'
          setDuplicatedField('Tên người dùng')
          setFieldValue(duplicatedField)
          return
        }
        else {
          document.querySelector('.validate').style.display = 'flex'
          setDuplicatedField('Email')
          setFieldValue(duplicatedField)
          return
        }
      }
      return login(userName, password, image)
    })
  }
  const handleFacebookLogin = () => {
    signInWithPopup(auth, provider).then(res => {
      addFacebookAccount(res.user)
    }).catch(e => console.log(e))
  }
  return (
    <div className="socialauth" onClick={handleFacebookLogin}>
        <img src={facebook_logo} alt=''/>
        <span>Facebook</span>
    </div>
  )
}

export default Facebook