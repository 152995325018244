import React from 'react'
import './Toastr.css'
import 'bootstrap-icons/font/bootstrap-icons.css'; 
const Toastr = ({icon, message, style}) => {
  const thisIcon = () => {
    switch (icon) {
        case 'success':
            return <i className="bi bi-check-circle"></i>
        case 'info':
            return <i className="bi bi-info-circle"></i>
        case 'warning':
            return <i className="bi bi-exclamation-triangle"></i>
        case 'error':
            return <i className="bi bi-bug"></i>
        default:
            return
    }
  }
  const onClick = (e) => {
    e.currentTarget.style.opacity = 0
    setTimeout(() => {
        if(e.currentTarget)
        e.currentTarget.style.display = 'none'
    }, 2000)
    return
  }
  return (
    <div className="toastr" style={style} onClick={(e) => onClick(e)}>
        <div>{thisIcon()}</div>
        <span>{message}</span>
    </div>

  )
}

export default Toastr