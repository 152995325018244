import Navbar from './Components/Navbar/Navbar'
import { useContext, useEffect } from 'react';
import './App.css';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import Shop from './Pages/Shop';
import Cart from './Pages/Cart/Cart';
import ShopCategory from './Pages/ShopCategory/ShopCategory';
import Product from './Pages/Product/Product';
import LoginSignup from './Pages/LoginSignup/LoginSignup';
import Footer from './Components/Footer/Footer';
import men_banner from './Components/Assets/banner_mens.png'
import women_banner from './Components/Assets/banner_women.png'
import kids_banner from './Components/Assets/banner_kids.png'
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import Login from './Pages/Login/Login';
import Policy from './Pages/Policy/Policy';
import { ShopContext } from './Context/ShopContext';
import UserInfo from './Pages/UserInfo/UserInfo';
import Profile from './Pages/UserInfo/Profile/Profile';
import PhotoChoosing from './Pages/UserInfo/PhotoChoosing/PhotoChoosing';
import PaymentMethod from './Pages/UserInfo/PaymentMethod/PaymentMethod';
import ChangePassword from './Pages/ForgotPassword/ChangePassword/ChangePassword';
import SendEmail from './Pages/ForgotPassword/SendEmail/SendEmail';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import Checkout from './Pages/Checkout/Checkout';
import { gapi } from 'gapi-script'
import Toastr from './Components/Toastr/Toastr';
import Validate from './Components/SocialAuth/Validate';
import PurchaseHistory from './Pages/UserInfo/PurchaseHistory/PurchaseHistory';
function App() {
  const {data} = useContext(ShopContext)
  const entries = performance.getEntriesByType("navigation");
  entries.forEach((e) => {
    if(e.type === 'reload')
    {
        document.body.style.overflow = 'hidden'
        const container = document.querySelector('.container-loader')
        const loader = document.querySelector('.loader')
        container.style.display = 'block'
        container.classList.add('active')
        loader.style.display = 'block'
        if(data) 
        {
          document.body.style.overflowY = 'scroll'
          container.style.display = 'none'
          container.classList.remove('active')
          loader.style.display = 'none'
        }
    }
  })
  useEffect(() => {
    const start = () => {
      gapi.client.init({
        clientId: '656848930182-e3fap9apmt8o8904or0f3br4luesh3qe.apps.googleusercontent.com',
        scope: ''
      })
    }
    gapi.load('client:auth2', start)
  }, [])
  return (
   <div>
    <Toastr/>
    <Validate/>
    <BrowserRouter>
    <Navbar/>
    <PhotoChoosing/>
      <Routes>
        <Route path='/' element={<Shop/>}/>
        <Route path='/policy' element={<Policy/>}/>
        <Route path='/men' element={<ShopCategory banner={men_banner} classify="men"/>}/>
        <Route path='/women' element={<ShopCategory banner={women_banner} classify="girl"/>}/>
        <Route path='/kids' element={<ShopCategory banner={kids_banner} classify="children"/>}/>
        <Route path='/product' element={<Product/>}/>
        <Route path='/cart' element={localStorage.getItem('session-token') ? <Cart/> : <Navigate to='/'/>}/>
        <Route path='/cart/checkout' element={localStorage.getItem('session-token') ? <Checkout/> : <Navigate to='/'/>}/>
        <Route path='/signup' element={<LoginSignup/>}/>
        <Route path='/login' element={localStorage.getItem('session-token') ? <Navigate to='/'/> : <Login/>}/>
        <Route path='/user' element={localStorage.getItem('session-token') ? <UserInfo/> : <Navigate to='/login'/>}>
          <Route path='profile' element={<Profile/>}/>
          <Route path='payment-method' element={<PaymentMethod/>}/>
          <Route path='purchase-history' element={<PurchaseHistory/>}/>
        </Route>
        <Route path='/forgotpassword' element={<ForgotPassword/>}>
          <Route path='sendemail' element={<SendEmail/>}/>
          <Route path='changepassword' element={<ChangePassword/>}/>
        </Route>
      </Routes>
    <ScrollToTop/>
    <Footer/>
    </BrowserRouter>
   </div>
  );
}

export default App;
