import React, {useContext, useEffect, useState, useRef} from 'react'
import './ProductDisplay.css'
import star_icon from '../Assets/star_icon.png'
import star_dull_icon from '../Assets/star_dull_icon.png'
import { ShopContext } from '../../Context/ShopContext'
import "bootstrap-icons/font/bootstrap-icons.css";
const ProductDisplay = (props) => {
  const {getItemToCart, favorite, setFavorite} = useContext(ShopContext)
  const {product} = props
  const [yAxis, setYAxis] = useState(0)
  const sizeRes = useRef()
  const [size, setSize] = useState(null)
  const handleClick = (action, e) => {
    if(action === '+')
    {
        ++e.value
    }
    else if(action === '-' && e.value > 0)
    {
        --e.value
    }
    else return;
  }
  const handleAddToCart = (e) => {
    if(e.value !== 0 && size !== null)
    {
        setYAxis(window.scrollY)
        let new_price = 0
        if(size === 'S')
            new_price += product.price
        else if(size === 'M')
            new_price += product.price * 1.2
        else if(size === 'L')
            new_price += product.price * 1.3
        else if(size === 'XL')
            new_price += product.price * 1.4
        else if(size === 'XXL')
            new_price += product.price * 1.5
        const item = {
            size: size,
            quantity: Number(e.value),
            price: new_price,
            productId: product._id
        }
        console.log(item)
        getItemToCart(item, product.imageCover, product.name)
        document.querySelectorAll('.productdisplay-right-sizes div').forEach((size) => {
            size.style.background = '#fbfbfb';
        });
    }
    else return;
  }

  const chooseSize = (e) => {
    setYAxis(window.scrollY)
    e.target.parentNode.querySelectorAll('div').forEach((size) => {
        size.style.background = '#fbfbfb';
    });
    e.target.style.background = '#c4c3c3';
    setSize(e.target.textContent)
  }
  useEffect(() => {
    window.scrollTo(0, yAxis)
  })
  const chooseSizeRes = () => {
    setYAxis(window.scrollY)
    setSize(sizeRes.current.value) 
  }
  const setMainImage = (e) => {
    document.querySelector('.productdisplay-main-img').src = e.currentTarget.src
  }
  const addFavourite = async () => {
    const token = localStorage.getItem('session-token')
    if(!token)
        return
    setFavorite(prev => {
        if(!prev && !Array.isArray(prev))
            prev = []
        return [...prev, product._id]
    })
    await fetch('https://qhn-api.onrender.com/user/favorite', {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({productId: product._id})
    })
  }
  const removeFavorite = async () => {
    const token = localStorage.getItem('session-token')
    if(!token)
        return
    setFavorite(prev => {
        if(!prev && !Array.isArray(prev))
            prev = []
        return prev.filter(id => id !== product._id)
    })
    await fetch('https://qhn-api.onrender.com/user/favorite', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({productId: product._id})
    })
  }
  return (
    <div className='productdisplay'>
        <div className="productdisplay-left">
            <div className="productdisplay-img">
                <div className="productdisplay-img-list">
                    <img src={product.imageCover} alt="product_image.png" onClick={(e) => setMainImage(e)}/>
                    {
                        product.images.map((item, index) => {
                            return <img key={index} src={item} alt="product_image.png" onClick={(e) => setMainImage(e)}/>
                        })
                    }
                    
                </div>
                <img className='productdisplay-main-img' src={product.imageCover} alt=''/>
            </div>
        </div>
        <div className="productdisplay-right">
            <div className='productdisplay-title'>
                <h1>{product.name}</h1> 
                <h1 onClick={(favorite && Array.isArray(favorite) && favorite.includes(product._id)) ? removeFavorite : addFavourite}>
                    {(favorite && Array.isArray(favorite) && favorite.includes(product._id)) ?  
                    <i class="bi bi-heart-fill"></i>
                    : <i class="bi bi-heart"></i>
                    }
                </h1>
            </div>
            <div className="productdisplay-right-star">
                <img src={star_icon} alt="product_image.png" />
                <img src={star_icon} alt="product_image.png" />
                <img src={star_icon} alt="product_image.png" />
                <img src={star_icon} alt="product_image.png" />
                <img src={star_dull_icon} alt="product_image.png" />
                <p>({product.comments.length})</p>
            </div>
            <div className="productdisplay-right-prices">
                <div className="productdisplay-right-price-new">{product.price.toLocaleString()}đ</div>
                <div className="productdisplay-right-price-old">{(product.price * 1.5).toLocaleString()}đ</div>
            </div>
            <div className="productdisplay-right-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
            </div>
            <div className="productdisplay-right-size">
                <label htmlFor="select"><h1>Chọn Size</h1></label>
                <div className="productdisplay-right-sizes">
                    {
                        product && product.size.map((item, index) => {
                            return <div key={index} id={item._id} onClick={(e) => chooseSize(e)}>{item.nameSize}</div>
                        })
                    }
                </div>
                <select id='select' onChange={chooseSizeRes} ref={sizeRes} className='productdisplay-right-sizes-responsive'>
                    <option value="" disabled>Chọn size của bạn</option>
                    <option value="S">Size S</option>
                    <option value="M">Size M</option>
                    <option value="L">Size L</option>
                    <option value="XL"> Size XL</option>
                    <option value="XXL">Size XXL</option>
                </select>
            </div>
            <div className="productdisplay-right-quantity">
                <h1>Số lượng</h1>
                <div className='productdisplay-quantity-picking' id='quantity-picking'>
                    <div onClick={(e) => {handleClick('-', e.target.parentNode.querySelector('input'))}}>-</div>
                    <input type='number' defaultValue={1} min='0'/>
                    <div onClick={(e) => {handleClick('+', e.target.parentNode.querySelector('input'))}}>+</div>
                </div>
            </div>

            <button onClick={() => {handleAddToCart(document.getElementById('quantity-picking').querySelector('input'))}}>Thêm vào giỏ hàng</button>
        </div>
    </div>
  )
}

export default ProductDisplay