import React, {useState, useEffect, useContext} from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import default_avatar from '../../Components/Assets/defaultAvatar.png'
import "bootstrap-icons/font/bootstrap-icons.css";
import cart from '../../Components/Assets/logo.png'
import './UserInfo.css'
import logo from '../../Components/Assets/hoodie.png'
import { ShopContext } from '../../Context/ShopContext'
const UserInfo = () => {
  const {totalItem, data, clearCurrentCart} = useContext(ShopContext)
  const location = useLocation()
  const hideEmail = (email) => {
    let parts = email.split("@");
    let hiddenPart = parts[0].substring(0, Math.min(3, parts[0].length));
    hiddenPart += "*****";
    let hiddenEmail = hiddenPart + "@" + parts[1];
    return hiddenEmail;
  }
  const render = () => {
    setInfo(JSON.parse(localStorage.getItem('user-info')))
  }
  const logout = () => {
    if(!data)
        return
    clearCurrentCart()
    localStorage.removeItem('user-info')
    localStorage.removeItem('session-token')
    window.location.href = '/'
  }
  const edit = () => {
    document.querySelectorAll('.profile-maincontext div input:not(#gender)').forEach((i) => {
        i.disabled = false
    })
    document.querySelector('#gender').style.display = 'none'
    document.querySelector('.profile-maincontext button').style.display = 'flex'
    document.querySelector('.profile-maincontext div select').style.display = 'block'
  }
  const routing = () => {
    const url = window.location.pathname
    document.querySelectorAll('.userinfo-sidenav-wrapper').forEach((i) => {
        if(url === `/user/${i.id}`)
        {
            i.style.backgroundColor = 'rgb(97, 97, 248)'
            i.querySelector('a span').style.color = 'white'
            i.querySelector('label').style.color = 'white'
        }
        else {
            i.style.backgroundColor = 'white'
            i.querySelector('a span').style.color = 'rgb(97, 97, 248)'
            i.querySelector('label').style.color = 'rgb(97, 97, 248)'
        }
    })
  }
  useEffect(() => {
    render()
    routing()
  }, [])
  useEffect(() => {
    routing()
  }, [location])
  const [info, setInfo] = useState()
  return (
    <>
    <div className='userinfo-container'>
    <div className='userinfo'>
    <div className="userinfo-left">
    <div className="userinfo-avatar-section">
        <img src={(info && info.hasOwnProperty('photo') && info.photo)? info.photo : default_avatar} alt=''/>
        <span className="userinfo-hellospan">Xin chào, {info && info.userName} </span>
    </div>
    <div className="userinfo-context">
        <div className="userinfo-sidenav">
            <div className='userinfo-logo'>
                <img src={logo} alt="logo.png"/>
                <p>QHN SHOP</p>
            </div>
            <div className='userinfo-sidenav-wrapper' id='profile'>
                <label>
                <i className="bi bi-person-fill"></i>
                </label>
                <Link to='/user/profile'>
                <span>Thông tin cá nhân</span>
                </Link>
            </div>
            <div className='userinfo-sidenav-wrapper' id='purchase-history'>
                <label>
                <i className="bi bi-clock-history"></i>
                </label>
                <Link to='/user/purchase-history'>
                <span>Lịch sử giao dịch</span>
                </Link>
            </div>
            <div className='userinfo-sidenav-wrapper' id='payment-method'>
                <label>
                <i className="bi bi-credit-card-fill"></i>
                </label>
                <Link to='/user/payment-method'>
                <span>Phương thức thanh toán</span>
                </Link>
            </div>
            <div className='userinfo-sidenav-wrapper' id='favourite'>
                <label>
                <i className="bi bi-heart-fill"></i>
                </label>
                <Link>
                <span>Sản phẩm yêu thích</span>
                </Link>
            </div>
            <div className='userinfo-sidenav-wrapper' onClick={edit}>
                <label>
                <i className="bi bi-gear"></i>
                </label>
                <Link to='/user/profile'>
                <span>Cài đặt</span>
                </Link>
            </div>
            <div className='userinfo-sidenav-wrapper' onClick={logout}>
                <label>
                <i className="bi bi-box-arrow-left"></i>
                </label>
                <Link>
                <span>Đăng xuất</span>
                </Link>
            </div>
            <div className="userinfo-picture">
                <img src={cart} alt=''/>
                    <h4>Có {totalItem ? totalItem : 0} sản phẩm trong giỏ hàng của bạn</h4>
                <div>
                    <label>Xem giỏ hàng</label>
                    <Link to='/cart'>
                    <i className="bi bi-arrow-right"></i>
                    </Link>
                </div>
            </div>
        </div>
        <Outlet context={[info, hideEmail]}/>
    </div>
</div>
<div className="userinfo-right">
    <div className="userinfo-avatar">
        <img src={(info && info.hasOwnProperty('photo') && info.photo) ? info.photo : default_avatar} alt=''/>
        <label id="photoChoosing">
        <i className="bi bi-pencil-square"></i>
        <input type="file" accept="image/png, image/jpeg" />          
        </label>
    </div>
    <div className="userinfo-name">
        <span>{info && info.userName}</span>
    </div>
    <div className="userinfo-email">
        <span>{(info && info.hasOwnProperty('email') && info.email) ? hideEmail(info.email) : ''}</span>
    </div>
    <div className="userinfo-history">
        <div>
            <div className="userinfo-history-tag">Đã tìm kiếm</div>
            <span>1000</span>
        </div>
        <div>
            <div className="userinfo-history-tag">Đơn hàng</div>
            <span>1000</span>
        </div>
        <div>
            <div className="userinfo-history-tag">Yêu thích</div>
            <span>1000</span>
        </div>
        <div>
            <div className="userinfo-history-tag">Đánh giá</div>
            <span>1000</span>
        </div>
    </div>
    <div className="userinfo-your-rank">
        <div className="userinfo-your-rank-tag">
            <h4>Xếp hạng</h4>
        </div>
        <div className='userinfo-level-container'>
        <div className="userinfo-level">
            <span>Sán phẩm đã bán</span>
            <div className="userinfo-progress">
                <div className="userinfo-progressbar">
                    <div className="userinfo-subprogressbar">50%</div>
                </div>
            </div>
        </div>
        <div className="userinfo-level">
            <span>Sản phẩm đã mua</span>
            <div className="userinfo-progress">
                <div className="userinfo-progressbar">
                    <div className="userinfo-subprogressbar">50%</div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
</div>
</div>
</>
  )
}

export default UserInfo