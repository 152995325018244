import React from 'react'

const Description = (props) => {
  const {product} = props
  return (
    <div className="descriptionbox-description">
      <p>
      {
        product &&
        product.description
      }
      </p>
    </div>
  )
}

export default Description