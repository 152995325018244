import React, {useState, useEffect, useContext} from 'react'
import { useLocation } from 'react-router-dom'
import './Checkout.css'
import logo from '../../Components/Assets/hoodie.png'
import check from '../../Components/Assets/check_mark.png'
import { ShopContext } from '../../Context/ShopContext'
const Checkout = () => {
  const {clearCart, cartItems} = useContext(ShopContext)
  const location = useLocation()
  const query = location.search
  let amount = () => {
    const urlParams = new URLSearchParams(query)
    const amountParam = urlParams.get('amount')
    return parseInt(amountParam);
  }
  const paidContent = 'Thanh toán đơn hàng QHN SHOP'
  const [products, setProducts] = useState({})
  const [notification, setNotification] = useState('Đơn hàng hết hạn sau:')
  const [seconds, setSeconds] = useState(parseInt(localStorage.getItem('timerSeconds')) || 600);
  const [isPaidSuccess, setPaidSuccess] = useState(false)
  const checkout = async () => {
    const customer = JSON.parse(localStorage.getItem('user-info'))
    const token = localStorage.getItem('session-token')
    if(!customer.hasOwnProperty('Address'))
        return
    const data = {
        status: 'Đang xử lý',
        products: products,
        paymentMethod: customer.paymentMethod,
        shippingAddress: customer.Address
    }
    await fetch(`https://qhn-api.onrender.com/user/createOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then(res => {
        if(!res.status === 'success')
            return
        else {
            clearCart()
            document.querySelector('.toastr div').innerHTML = '<i class="bi bi-check-circle"></i>'
            document.querySelector('.toastr span').innerText = 'Tạo đơn hàng thành công!'
            document.querySelector('.toastr').style.backgroundColor = '#9e8c02'
            document.querySelector('.toastr').style.display = 'flex'
            localStorage.setItem('timerSeconds', 3)
            setSeconds(2)
            setNotification('Bạn sẽ được chuyển hướng sau:')
            setPaidSuccess(true)
        }
      })
  }
  const checkPaid = async (content, price) => {
    if(isPaidSuccess)
        return
    await fetch(`https://script.google.com/macros/s/AKfycbyiueZdb8NNOxkH-71LzaeguW4PnyILBR0TjSPE_HxT7EDS9lbXlDthT4QTwovnxtom/exec`)
        .then(res => res.json())
        .then(res => {
            if(!res.error)
            {
                let lastPaid = res.data[res.data.length - 1]
                if(lastPaid['Giá trị'] !== price || !lastPaid['Mô tả'].includes(content))
                    return
                else if(lastPaid['Giá trị'] === price && lastPaid['Mô tả'].includes(content)) 
                    return checkout()
            }
    })} 
  useEffect(() => {
    const storedSeconds = parseInt(localStorage.getItem('timerSeconds'));
    if (storedSeconds && storedSeconds > 0) 
      setSeconds(storedSeconds);
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds(prevSeconds => {
        const updatedSeconds = prevSeconds - 1;
        localStorage.setItem('timerSeconds', updatedSeconds);
        return updatedSeconds;
        });
    }, 1000);
    return () => clearInterval(interval);
    }
    else if(seconds <= 0)
    {
        window.location.href = '/cart'
    }
  }, [seconds])
  useEffect(() => {
    const interval = setInterval(() => checkPaid(paidContent, amount()), 1000)
    return () => clearInterval(interval);
     // eslint-disable-next-line
  }, [isPaidSuccess, checkPaid, setPaidSuccess])

  useEffect(() => {
    const cart = cartItems && Object.entries(cartItems)
     // eslint-disable-next-line
    .map(e => {
    if (e[1].hasOwnProperty('quantity') && e[1]['quantity'] > 0) 
      return e;
  })
  .filter(Boolean)

  const convertedArray = cart && cart.map(obj => {
    return Object.entries(obj[1]).reduce((acc, [key, value]) => {
      const keyMap = {
        name: "productName", 
        new_price: "price", 
      };
      const newKey = keyMap[key] || key;
      return {
        ...acc,
        productId: obj[0],
        [newKey]: value
      };
    }, {});
  });
  setProducts(convertedArray)
  }, [cartItems])
  return (
    <div className="checkout">
        <div className='checkout-wrapper'>
        <div className="checkout-left">
            <div className="checkout-info">
                <h3>Thông tin đơn hàng</h3>
                <span>Nhà cung cấp</span>
                <div className='checkout-info-row'>
                    <img src={logo} alt=''/>
                    <p>QHN Shop</p>
                </div>
                <hr/>
                <span>Mã đơn hàng</span>
                <div className="checkout-info-row">
                    <p>{localStorage.getItem('session-token') ? localStorage.getItem('session-token') : ''}</p>
                </div>
                <hr/>
                <span>Mô tả</span>
                <div className="checkout-info-row">
                    <p>{paidContent}</p>
                </div>
                <hr/>
                <span>Số tiền</span>
                <div className="checkout-info-row">
                    <h2>{amount().toLocaleString('en-US')}đ</h2>
                </div>
            </div>
            <div className="checkout-expire">
                <p>{notification}</p>
                <div className='checkout-expire-clock'>
                    <div>
                        <p>{Math.floor(seconds / 60) < 10 ? `0${Math.floor(seconds / 60)}` : 10}</p>
                        <span>Phút</span>
                    </div>
                    <div>
                        <p>{`${seconds % 60 < 10 ? 0 : ''}${seconds % 60}`}</p>
                        <span>Giây</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="checkout-right">
            <h2>Quét mã QR để thanh toán</h2>
            {
                isPaidSuccess ? (
                <>
                <img src={check} alt=''/>
                <h3>Giao dịch thành công</h3>
                </>
            ) : 
            (<>
            <img src={`https://img.vietqr.io/image/vietinbank-100879787056-compact2.png${query}&addInfo=${paidContent}`} alt=''/>
            <span>Sử dụng ứng dụng camera hỗ trợ QR code để quét mã</span>
            </>)
            }
        </div>
        </div>
    </div>
  )
}

export default Checkout