import React, {useState, useContext, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import './Navbar.css'
import logo from '../Assets/hoodie.png'
import cart_icon from '../Assets/cart_icon.png'
import search_icon from '../Assets/search_icon.png'
import dropdown_menu from '../Assets/dropdown_menu.png'
import default_avatar from '../Assets/defaultAvatar.png'
import { ShopContext } from '../../Context/ShopContext'
const Navbar = () => {
    const {totalItem, cartReview, data} = useContext(ShopContext)
    const info = JSON.parse(localStorage.getItem('user-info'))
    const [filtered, setFiltered] = useState()
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1)
    const [dropdown, setDropdown] = useState(false)
    const [menu, setMenu] = useState(false)
    const [avatar, setAvatar] = useState()
    const filteredListRef = useRef(null);
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current && selectedItemIndex !== -1) {
            inputRef.current.value = filtered[selectedItemIndex].name;
        }
    }, [selectedItemIndex, filtered]);
    useEffect(() => {
        if (filteredListRef.current && selectedItemIndex !== -1) {
            const selectedItem = filteredListRef.current.children[selectedItemIndex];
            if (selectedItem) {
                selectedItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, [selectedItemIndex]);
    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault(); 
            setSelectedItemIndex(prevIndex => Math.min(prevIndex + 1, filtered.length - 1));
        } else if (e.key === 'ArrowUp') {
            e.preventDefault(); 
            setSelectedItemIndex(prevIndex => Math.max(prevIndex - 1, -1));
        }
    };
    const handleItemClick = (value) => {
        inputRef.current.value = value;
        window.location.href = `?name=${value}`;
    };
    const search = (e) => {
        const value = e.currentTarget.value.trim()
        if(!value)
        {
            setFiltered([])
            setSelectedItemIndex(-1);
            return
        }
        
        if(data)
        {
            const products = data.data.products;
            const filteredProducts = products.filter(({ name }) => name.toUpperCase().includes(value.toUpperCase()));
            setFiltered(filteredProducts);
            const d = products.find(({name}) => name === value)
            if(e.key === 'Enter' && d)
            {
                handleItemClick(value)
            }
        }
    }
    useEffect(() => {
        setAvatar(() => {
            return (info && info.hasOwnProperty('photo') && info.photo) ? info.photo : default_avatar
        })
    }, [info])
    const dropdownmenu = () => {
        if(dropdown)
        {
            document.getElementById('dropdown').style.transform = 'translateY(-2px)'
            document.getElementById('dropdown-hr').style.display = 'block'
            document.getElementById('dropdown-hr').style.transform = 'translateY(1px)'
            document.getElementById('dropdown-content').style.display = 'block'
            setDropdown(!dropdown)
        }
        else {
            document.getElementById('dropdown').style.transform = 'none'
            document.getElementById('dropdown-hr').style.display = 'none'
            document.getElementById('dropdown-hr').style.transform = 'none'
            document.getElementById('dropdown-content').style.display = 'none'
            setDropdown(!dropdown)
        }
    }
    const dropdownToggle = (e) => {
        !menu ? e.currentTarget.querySelector('ul').style.display = 'inline-block' : e.currentTarget.querySelector('ul').style.display = 'none'
        setMenu(!menu)
    }
    const navigate = (category) => {
        document.getElementById('shop').querySelector('hr').style.display = 'none'
        const lists = document.getElementById('dropdown-content').querySelectorAll('li')
        lists.forEach((li) => {
            li.querySelector('hr').style.display = 'none'
        })
        document.getElementById(category).querySelector('hr').style.display = 'block'
        document.getElementById('dropdown').style.transform = 'none'
        document.getElementById('dropdown-hr').style.display = 'none'
        document.getElementById('dropdown-content').style.display = 'none'
        setDropdown(!dropdown)
    }
  return (
    <div className='navbar'>
        <Link style={{textDecoration: 'none'}} to='/'>
            <div className='nav-logo' onClick={() => {navigate('shop')}}>
                <img src={logo} alt="logo.png"/>
                <p>QHN SHOP</p>
            </div>
        </Link>
        <ul className='nav-menu'>
            <li onClick={() => {navigate('shop')}} id='shop'><Link style={{textDecoration: 'none'}} to='/'>Trang chủ</Link><hr/></li>
            <div className='nav-dropdown-menu' >
                <li onClick={() => {dropdownmenu('category')}} id='dropdown'>Danh mục</li>
                <hr id='dropdown-hr'/>
                <ul className="nav-dropdown-content" id='dropdown-content'>
                <Link  style={{textDecoration: 'none'}} to='/men'><li onClick={() => {navigate('men')}} id='men'>Nam giới<hr/></li></Link>
                <Link  style={{textDecoration: 'none'}} to='/women'><li onClick={() => {navigate('women')}} id='women'>Phái nữ<hr/></li></Link>
                <Link  style={{textDecoration: 'none'}} to='/kids'><li onClick={() => {navigate('kids')}} id='kids'>Trẻ em<hr/></li></Link>
                </ul>
            </div>
        </ul>  
        
        <div className='nav-searchbar-container'>
        <div className='nav-searchbar'>
                <img src={search_icon} alt='search_icon.png'/>
                <input type='text' placeholder='Tìm kiếm sản phẩm' ref={inputRef} onInput={(e) => search(e)} onKeyDown={handleKeyDown}/>
        </div>
        <div className='nav-searchbar-filteredlist' ref={filteredListRef}>
            {
                filtered &&
                filtered.map((item, index) => {
                    return (
                        <div
                        key={index}
                        className={index === selectedItemIndex ? 'selected-row' : ''}
                        onClick={() => window.location.reload()} 
                        onMouseOver={(e) => document.querySelector('.nav-searchbar input').value = e.currentTarget.innerText} onMouseLeave={() => document.querySelector('.nav-searchbar input').value = ''}> <Link to={{
                            pathname: '/product',
                            search: `?_id=${item._id}`
                          }}>{item.name}</Link></div>
                    )
                })
            }
        </div>
        </div>
        <div className='nav-login-cart'>
            {
                localStorage.getItem('session-token') ? 
                (<
                Link to='/user/profile' style={{textDecoration: 'none'}} id='user-info'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
                <img src={avatar ? avatar : default_avatar} alt='default_avatar.png'/>
                </Link>)
                : (<Link to='/login' style={{textDecoration: 'none'}} id='login-btn'><button>Đăng nhập</button></Link>)
            }
            <div className='nav-menu-responsive-dropdown' onClick={dropdownToggle}>
                <img src={dropdown_menu} alt=''/>
                <ul className='nav-menu-responsive-dropdown-content'>
                    <Link to='/'><li onClick={() => {navigate('shop')}} id='shop'>Trang chủ</li></Link>
                    <Link to='/men'><li onClick={() => {navigate('men')}} id='men'>Nam giới</li></Link>
                    <Link to='/women'><li onClick={() => {navigate('women')}} id='women'>Phái nữ</li></Link>
                    <Link to='/kids'><li onClick={() => {navigate('kids')}} id='kids'>Trẻ em</li></Link>
                    {
                        localStorage.getItem('session-token') ? 
                        (<Link to='/user/profile'><li id='user-profile-page'>Trang cá nhân</li></Link>) :
                        (<Link to='/login'><li id='login'>Đăng nhập</li></Link>)
                    }
                </ul>
            </div>
            <Link to='/cart' style={{textDecoration: 'none'}}>
                <div className='nav-counter'>
                    <div className="nav-counter-dropdown-menu">
                        <img src={cart_icon} alt='cart_icon.png'/>
                        <div className="nav-counter-dropdown-content">
                            <div className="nav-counter-dropdown-header">
                                <span>Hình ảnh</span>
                                <span>Size</span>
                                <span>Tên sản phẩm</span>
                                <span>Số lượng</span>
                            </div>
                            <hr/>
                            {
                                cartReview &&
                                cartReview.map((item, index) => {
                                    return <div key={index} className="nav-counter-dropdown-row">
                                        <div>
                                            <img src={item.image} alt={`${item.name}.png`}/>
                                        </div>
                                        <span>{item.size}</span>
                                        <span id='product_name'>{item.name}</span>
                                        <span>{item.quantity}</span>
                                    </div>
                                })
                            }
                           
                        </div>
                    </div>
                    <div className='nav-cart-count'>{totalItem ? totalItem : 0}</div>
                </div>
            </Link>
        </div>
    </div>
  )
}

export default Navbar