import React, {useEffect, useState, useRef, useContext} from 'react'
import { ShopContext } from '../../Context/ShopContext';
import avatar from '../Assets/defaultAvatar.png'
import 'bootstrap-icons/font/bootstrap-icons.css'; 
const Review = (props) => {
  const {setData} = useContext(ShopContext)
  const {product} = props
  const [currentProduct, setCurrentProduct] = useState(product)
  const [info, setInfo] = useState(null)
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const stars = useRef()
  const comment = useRef()
  const sendReview = async (e) => {
    e.preventDefault()
    const userInfo = JSON.parse(localStorage.getItem('user-info'))
    const oldComments = JSON.parse(localStorage.getItem('user-comments'))
    const username = userInfo.userName
    const token = localStorage.getItem('session-token')
    const data = {
      userName: username,
      comment: comment.current.value,
      star: stars.current.value,
      commentCreateAt: Date.now()
    }
    await fetch(`https://qhn-api.onrender.com/product/comment/${product._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(res => res.json()).then(res => {
      if(res.status === 'success')
      {
        comment.current.value = ''
        return fetch('https://qhn-api.onrender.com/product')
      }
    }).then(res => res.json())
    .then(async(res) => {
      setData(res)
      const query = window.location.search
      return await fetch(`https://qhn-api.onrender.com/product${query}`)
    }).then(res => res.json()).then(res => {
     setCurrentProduct(res.data.products[0])
    }).finally(() => {
        const info = {
          productId: product._id,
          image: product.imageCover,
          title: product.name, 
          category: product.classify
        }
        const newComments = [...oldComments, {...data, ...info}]
        localStorage.setItem('user-comments', JSON.stringify(newComments))
    })
  }
  const handleHover = (index) => {
    setHoverIndex(index);
};

const handleClick = (index) => {
    setSelectedIndex(index === selectedIndex ? -1 : index);
};

const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
        const starClass = i <= (hoverIndex !== -1 ? hoverIndex : selectedIndex) ? 'bi-star-fill' : 'bi-star';
        const starStyle = {
            cursor: 'pointer',
        };
        stars.push(
            <i
                key={i}
                className={`bi ${starClass}`}
                onMouseEnter={() => handleHover(i)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => handleClick(i)}
                style={starStyle}
            ></i>
        );
    }
    return stars;
};
    useEffect(() => {setInfo(JSON.parse(localStorage.getItem('user-info')))}, []);
    const addZeroIfNeeded = (num) => {
      return (num < 10 ? '0' : '') + num;
    }
    const dateConverting = (time) => {
      let commentDate = new Date(time);
      let year = commentDate.getFullYear();
      let month = commentDate.getMonth() + 1;
      let date = commentDate.getDate();
      return year + "-" + addZeroIfNeeded(month) + "-" + addZeroIfNeeded(date);
    }
  return (
    <div className='descriptionbox-review'>
        <form className="descriptionbox-reviewbox" id='review-form' onSubmit={sendReview}>
            <h1>Đánh giá sản phẩm</h1>
              <label htmlFor='rating'>
                  Xếp hạng * :
                  <input type='hidden' value={selectedIndex !== -1 ? selectedIndex + 1 : ''} defaultValue={1} ref={stars}/>
                  <div className="descriptionbox-star" id='rating'>
                  {renderStars()}
                  </div>
                </label>
              <label>
              <img src={(info && info.hasOwnProperty('photo') && info.photo) ? info.photo : avatar} alt=''/>
              <div className={`descriptionbox-review-textarea ${info ? '' : 'disabled'}`}>
              <textarea placeholder='Viết đánh giá của bạn *' ref={comment} disabled={info ? false : true} rows={5} required/>
              <button type='submit' form='review-form' className={info ? '' : 'disabled'} disabled={info ? false : true}>
              <i className="bi bi-send-fill"></i>
              </button>
              </div>
              </label>
        </form>
        <hr/>

        <h2>{
          currentProduct.comments.length > 0 ? 
        `${currentProduct.comments.length} đánh giá cho ${currentProduct.name}`
        : `${currentProduct.name} chưa có đánh giá nào.`} </h2>
        <div className="descriptionbox-reviewlist">
          {
            currentProduct.comments.map((item, index) => {
              return <div className="descriptionbox-review-row" key={index}>
              <img src={avatar} alt=''/>
              <div className='descriptionbox-review-right'>
                <div className='descriptionbox-title'>
                <p>{item.userName} <span> - {dateConverting(item.commentCreateAt)}</span></p>
                <div className="descriptionbox-star">
                 {
                   Array.from({ length: item.star }, (_, index) => <i className='bi bi-star-fill' key={index}></i>)
                 }
                 {
                  Array.from({ length: 5 - item.star }, (_, index) => <i className='bi bi-star' key={index}></i>)
                 }
                </div>
                </div>
                <p className='descriptionbox-review-box'>
                  {item.comment}
                </p>
              </div>
            </div>
            })
          }
        

        </div>
    </div>
  )
}

export default Review