import React, {useState} from 'react'
import './DescriptionBox.css'
import Description from './Description'
import Review from './Review'
const DescriptionBox = (props) => {
  const [route, setRoute] = useState(true)
  const {product} = props
  return (
    <div className='descriptionbox'>
        <div className="descriptionbox-navigator">
            <div className={`descriptionbox-nav-box ${route ? 'fade' : ''}`} onClick={() => setRoute(true)}>
                Mô tả
            </div>
            <div className={`descriptionbox-nav-box ${!route ? 'fade' : ''}`} onClick={() => setRoute(false)}>
                Bình luận
            </div>
        </div>
        {
          route ?
          (<Description product={product}/>)
          : (<Review product={product}/>)
        }
    </div>
  )
}

export default DescriptionBox