import React, {useState} from 'react'
import './PaymentMethod.css'

import money_icon from '../../../Components/Assets/money-symbol-icon-png.webp'
const PaymentMethod = () => {
  const [selected, setSelected] = useState(() => {
    const info = JSON.parse(localStorage.getItem('user-info'))
    return info ? info.paymentMethod : 'COD';
    })
    const change = async (e) => {
        const newSelected = e.target.value;
        setSelected(newSelected);
        const sessionToken = localStorage.getItem('session-token')
        const info = JSON.parse(localStorage.getItem('user-info'));
        info.paymentMethod = newSelected;
        localStorage.setItem('user-info', JSON.stringify(info));
        
        await fetch('https://qhn-api.onrender.com/user/editProfile', {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionToken}`,
          },
          body: JSON.stringify({ paymentMethod: newSelected }),
        });
      };
  return (
    <div className="paymentmethod-page">
    <div>
        <div className="paymentmethod-route">
        <h2>Phương thức thanh toán</h2>
        <span>Chọn phương thức thanh toán:</span>
        </div>
        <div className="paymentmethod-maincontext">
            <form>
            <div>
            <input type='radio' value='COD' name='COD' checked={selected === 'COD'} onChange={change}/>
            <label htmlFor='COD'>Tiền mặt (COD)</label>
            <img src={money_icon} alt=''/>
            </div>
            <div>
            <input type='radio' value='Chuyển khoản' name='Banking' checked={selected === 'Chuyển khoản'} onChange={change}/>
            <label htmlFor='Banking'>Chuyển khoản tới tài khoản ngân hàng</label>
            </div>
            </form>
        </div>
    </div>
</div>
)
}

export default PaymentMethod