// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, FacebookAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBYx0uRekXPf7pzxhKcAWk4HztVf-oJ9no",
  authDomain: "qhn-shop-9531b.firebaseapp.com",
  databaseURL: "https://qhn-shop-9531b-default-rtdb.firebaseio.com",
  projectId: "qhn-shop-9531b",
  storageBucket: "qhn-shop-9531b.appspot.com",
  messagingSenderId: "656848930182",
  appId: "1:656848930182:web:8fd657909a3b6ef0d21807",
  measurementId: "G-EVSH3PEV0F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app)
const provider = new FacebookAuthProvider()
export {auth, provider}