import React, {useRef} from 'react'
import './NewsLetter.css'
import { Link } from 'react-router-dom'
const NewsLetter = () => {
  const email = useRef()
  return (
    <div className='newsletter'>
        <h1>Ưu Đãi Độc Quyền Trên Email</h1>
        <p>Đăng ký & Cập Nhật Thông Tin Mới Nhất</p>
        <div>
          <input type='email' placeholder='Email của bạn' ref={email}/>
          <Link to={`/signup?email=${email.current ? email.current.value : ''}`}><button>Đăng ký</button></Link>
        </div>
    </div>
  )
}

export default NewsLetter