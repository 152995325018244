import React, {useState, useEffect, useContext, useMemo} from 'react'
import './Popular.css'
import Item from '../Item/Item'
import CarouselSlide from '../CarouselSlide/CarouselSlide'
import arrow_left from '../Assets/arrow-left.png'
import arrow_right from '../Assets/arrow-right.png'
import { ShopContext } from '../../Context/ShopContext'
const Popular = () => {
    const {data} = useContext(ShopContext)
    const popular = useMemo(() => {
      if (data) {
        return data.data.products.filter(i => i.classify === 'girl').slice(0, 4);
      } else {
        return [];
      }
    }, [data]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [yAxis, setYAxis] = useState(0)
    const nextSlide = (images) => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setYAxis(window.scrollY)
    };
  
    const prevSlide = (images) => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      setYAxis(window.scrollY)
    };
    useEffect(() => {
      window.scrollTo(0, yAxis);
    }, [currentIndex, yAxis])
    useEffect(() => {
      const intervalId = setInterval(() => {
        nextSlide(data ? popular : [])
      }, 6000);
  
      return () => {
        clearInterval(intervalId);
      };
      // eslint-disable-next-line
  }, [data ? popular.length : 1, data, popular]);
  return (
    <div className="popular">
        <h1>PHỔ BIẾN CHO PHÁI ĐẸP</h1>
        <hr/>
        <div className="popular-item">
            {
              popular && 
                popular.map((item, i) => {
                  return item.hasOwnProperty('_id') ?
                  <Item key={i} id={item._id} name={item.name} image={item.imageCover} new_price={item.price} old_price={item.price * 1.5}/>
                  : null
              }) 
            }
        </div>
        <div className="popular-item-carousel">
          {
            (popular && Array.isArray(popular) && popular.length !== 0 && popular[currentIndex]?.hasOwnProperty('_id')) ? (
              <>
                <button className="popular-prev" type='button' onClick={() => prevSlide(popular)}><img src={arrow_left} alt=''/></button>
                <CarouselSlide id={popular[currentIndex]._id} image={popular[currentIndex].imageCover} carouselid={'popular'} new_price={popular[currentIndex].price} old_price={popular[currentIndex].price * 1.5}/>
                <button className="popular-next" type='button' onClick={() => nextSlide(popular)}><img src={arrow_right} alt=''/></button>
              </>
            ) : (
              <p>Error: Something went wrong.</p>
            )
          }
        </div>
    </div>
  )
}

export default Popular