import React, {useContext} from 'react'
import './CartItem.css'
import { ShopContext } from '../../Context/ShopContext'
import "bootstrap-icons/font/bootstrap-icons.css";
const CartItem = () => {
  const {clearCart, removeFromCart, changeQuantity, cartAmount, cartReview, cartItems} = useContext(ShopContext) 
  const buttonAction = (index, action, child, price) => {
    action === '+' ? ++child.parentNode.querySelector('input').value :  --child.parentNode.querySelector('input').value
    changeQuantity(index, price, child.parentNode.querySelector('input').value)
  }
  const checkout = async () => {
    const paymentMethod = JSON.parse(localStorage.getItem('user-info')).paymentMethod
    if(!cartAmount || !cartItems)
      return
    if(paymentMethod === 'Chuyển khoản')
    {
      localStorage.setItem('timerSeconds', 600)
      return window.location.href = `/cart/checkout?amount=${cartAmount}`
    }
    else {
      const customer = JSON.parse(localStorage.getItem('user-info'))
      const token = localStorage.getItem('session-token')
      if(!customer.hasOwnProperty('Address'))
        return
      if(cartItems.length === 0)
      {
        document.querySelector('.toastr div').innerHTML = '<i class="bi bi-exclamation-triangle"></i>'
        document.querySelector('.toastr span').innerText = 'Tạo đơn hàng thất bại!'
        document.querySelector('.toastr').style.backgroundColor = '#c92c3c'
        document.querySelector('.toastr').style.display = 'flex'
        return
      }
      const data = {
        status: 'Đang xử lý',
        products: cartItems,
        paymentMethod: customer.paymentMethod,
        shippingAddress: customer.Address
      }
      await fetch(`https://qhn-api.onrender.com/user/createOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then(res => {
        if(!res.status === 'success')
          return
        else {
          clearCart()
          document.querySelector('.toastr div').innerHTML = '<i class="bi bi-check-circle"></i>'
          document.querySelector('.toastr span').innerText = 'Tạo đơn hàng thành công!'
          document.querySelector('.toastr').style.backgroundColor = '#9e8c02'
          document.querySelector('.toastr').style.display = 'flex'
        }
      })
    }
  }

  return (
    <div className='cartitem'>
        <div className="cartitem-format-main not-res">
            <div><p>Hình ảnh</p></div>
            <div><p>Tên sản phẩm</p></div>
            <div><p>Đơn giá</p></div>
            <div><p>Kích cỡ</p></div>
            <div><p>Số lượng</p></div>
            <div><p>Tổng thanh toán</p></div>
            <div><p></p></div>
        </div>
        <div className="cartitem-format-main res">
            <div><p>Ảnh</p></div>
            <div><p>Giá</p></div>
            <div><p>Size</p></div>
            <div><p>Số lượng</p></div>
            <div><p>Tổng</p></div>
            <div><p></p></div>
        </div>
        <hr/>
        {
          cartReview && cartReview.map((item, index) => {
            return <div key={index}>
            <div className="cartitem-format cartitem-format-main">
                <div><img src={item.image} alt='' className='cartitem-product-icon'/></div>
                <div id='name'><p>{item.name}</p></div>
                <p>{item.price && item.price.toLocaleString('en-US')}đ</p>
                <p>{item.size}</p>
                <div className='cartitem-quantity'>
                    <button onClick={(i) => buttonAction(index, '-', i.target, item.price)}>-</button>
                    <input type='number' 
                    onChange={(i) => changeQuantity(index, item.price, i.value)} 
                    defaultValue={item.quantity}/>
                    <button onClick={(i) => buttonAction(index, '+', i.target, item.price)}>+</button>
                </div>
                <div>
                  <p>{(item.quantity * item.price) !== 0 && (item.quantity * item.price).toLocaleString('en-US')}đ</p>
                </div>
                <div className='cartitem-removeicon' onClick={() => {removeFromCart(index, item.price, 
                      document.querySelector('.cartitem-quantity input').value)}}>
                    <i class="bi bi-trash"></i>
                </div>
            </div>
            <hr/>
        </div>
          })
        }
        <div className="cartitem-down">
            <div className="cartitem-total">
                <h1>Hóa đơn thanh toán</h1>
                <div>
                    <div className="cartitem-total-item">
                        <p>Tạm tính</p>
                        <p>{cartAmount ? cartAmount.toLocaleString('en-US') : 0}đ</p>
                    </div>
                    <hr/>
                    <div className="cartitem-total-item">
                        <p>Phí vận chuyển</p>
                        <p>Miễn phí</p>
                    </div>
                    <hr/>
                    <div className="cartitem-total-item">
                        <h3>Tổng thanh toán</h3>
                        <h3>{cartAmount ? cartAmount.toLocaleString('en-US') : 0}đ</h3>
                    </div>
                </div>
                <div className="cartitem-promocode res">
                <p>Nếu bạn có mã quà tặng, hãy nhập ở đây</p>
                <div className="cartitem-promobox">
                    <input placeholder='Mã của bạn'/>
                    <button>Tiếp tục</button>
                </div>
            </div>
                <button onClick={checkout}>THANH TOÁN</button>
            </div>
            <div className="cartitem-promocode not-res">
                <p>Nếu bạn có mã quà tặng, hãy nhập ở đây</p>
                <div className="cartitem-promobox">
                    <input placeholder='Mã của bạn'/>
                    <button>Tiếp tục</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CartItem