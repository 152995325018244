import React, {useState, useEffect, useContext} from 'react'
import './RelatedProducts.css'
import Item from '../Item/Item'
import CarouselSlide from '../CarouselSlide/CarouselSlide'
import { ShopContext } from '../../Context/ShopContext'
const RelatedProducts = (props) => {
  const {data} = useContext(ShopContext)
  const {classify} = props
  const relatedPros = data && [...data.data.products].filter(i => i.classify === classify).slice(0, 4)
  const [currentIndex, setCurrentIndex] = useState(0);
  const [yAxis, setYAxis] = useState(0)
  const nextSlide = (images) => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setYAxis(window.scrollY)
  };
  useEffect(() => {
      window.scrollTo(0, yAxis);
  }, [currentIndex, yAxis])
  useEffect(() => {
      const intervalId = setInterval(() => {
        nextSlide(relatedPros)
      }, 6000);
      return () => {
        clearInterval(intervalId);
      };
  }, [relatedPros]);
  return (
    <div className='relatedproducts'>
        <h1>Sản phẩm liên quan</h1>
        <hr/>
        <div className="relatedproducts-item">
            {
              relatedPros &&
              relatedPros.map((item, i) => {
                return <Item key={i} id={item._id} name={item.name} image={item.imageCover} new_price={item.price} old_price={item.price * 1.5}/>
              })
            }
        </div>
        <div className="relatedproducts-item-carousel">
          {
            relatedPros &&
            <CarouselSlide id={relatedPros[currentIndex]._id} image={relatedPros[currentIndex].imageCover} carouselid={'relatedproducts'} new_price={relatedPros[currentIndex].price} old_price={relatedPros[currentIndex].price * 1.5}/>
          }
        </div>
    </div>
  )
}

export default RelatedProducts