import React, {useEffect, useRef} from 'react'
import './LoginSignup.css'
import { Link, useLocation } from 'react-router-dom'
const LoginSignup = () => {
  const location = useLocation()
  const query = location.search
  const username = useRef()
  const email = useRef()
  const password = useRef()
  const passwordConfirm = useRef()
  useEffect(() => {
    if(query.includes('?email'))
    {
      document.querySelector('#email-box').value = query.slice(7)
    }
    else return 
  }, [query])
  const signup = async () => {
    if(passwordConfirm.current.value !== password.current.value)
    {
      return document.querySelector('#passwordconfirm').style.display = 'block'
    }
    if(passwordConfirm.current.value.length < 10 || password.current.value.length < 10)
    {
      const password = document.querySelector('#password')
      password.style.display = 'block'
      password.querySelector('p').innerText = 'Mật khẩu chứa tối thiểu 10 ký tự!'
      return
    }
    document.querySelectorAll('.loginsignup-input-container input').forEach((i) => {
      if(!i.value)
      {
        i.style.border = '1px solid red'
        i.classList.add('red-placeholder')
        i.parentNode.querySelector('.speech-bubble-container div').style.display = 'block'
        const errorText = i.parentNode.querySelector('.speech-bubble-container div p')
        errorText.innerText = `Bạn chưa ${errorText.getAttribute('data-input')}`
      }
      else {
        i.style.border = '1px solid #9d9c9c'
        i.classList.remove('red-placeholder')
        i.parentNode.querySelector('.speech-bubble-container div').style.display = 'none'
      }
    })
    
    const data = {
      userName: username.current.value,
      email: email.current.value,
      password: password.current.value,
      passwordConfirm: passwordConfirm.current.value
    }
    document.querySelector('.loginsignup-loader').style.display = 'block'
    await fetch('https://qhn-api.onrender.com/user/signup', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    }).then(res => res.json()).then(res => {
      document.querySelector('.loginsignup-loader').style.display = 'none'
      if(res.status === "fail")
      {
        if(res.message === "Invalid input data Please provide a valid email")
        {
          const email = document.querySelector('#email')
          email.style.display = 'block'
          email.querySelector('p').innerText = 'Email của bạn không hợp lệ. Vui lòng nhập lại email'
        }
        if(res.message.includes('Duplicate field value'))
        {
          const duplicateField = res.message.replace('Duplicate field value ', '')
          document.querySelectorAll('.loginsignup-input-container input').forEach((i) => {
            if(i.value === duplicateField)
            {
              const errorText = i.parentNode.querySelector('.speech-bubble-container div p')
              errorText.innerText = `${errorText.getAttribute('data-type')} đã tồn tại. Hãy sử dụng một ${errorText.getAttribute('data-type').toLowerCase()} khác`
              i.parentNode.querySelector('.speech-bubble-container div').style.display = 'block'
            }
          })
        }
      }
      else {
        alert('Đăng ký thành công!')
        window.location.href = '/login'
      }
    })
  }
  return (
    <div className='loginsignup'>
      <div className="loginsignup-container">
        <h1>Đăng ký</h1>
        <div className="loginsignup-fields">
          <div className='loginsignup-input-container'>
          <input type="text" placeholder='Tên của bạn' ref={username}/>
          <div className="speech-bubble-container">
          <div className='speech-bubble' id='username'>
            <p data-input="nhập tên người dùng" data-type="Tên người dùng"></p>
          </div>
          </div>
          </div>
          <div className='loginsignup-input-container'>
          <input type="email" placeholder='Email' autoComplete='new-password' ref={email} id='email-box'/>
          <div className="speech-bubble-container">
          <div className='speech-bubble' id='email'>
            <p data-input="nhập email" data-type="Email"></p>
          </div>
          </div>
          </div>
          <div className='loginsignup-input-container'>
          <input type="password" placeholder='Mật khẩu' autoComplete='new-password' ref={password}/>
          <div className="speech-bubble-container">
          <div className='speech-bubble' id='password'>
            <p data-input="nhập mật khẩu" data-type="Mật khẩu"></p>
          </div>
          </div>
          </div>
          <div className='loginsignup-input-container'>
      
          <input type="password" placeholder='Xác nhận mật khẩu' autoComplete='new-password' ref={passwordConfirm}/>
          <div className="speech-bubble-container">
          <div className='speech-bubble' id='passwordconfirm'>
            <p data-input="xác minh mật khẩu" data-type="Xác minh mật khẩu">Xác minh mật khẩu không trùng khớp</p>
          </div>
          </div>
          </div>
        </div>
        <button onClick={signup}>
          <span>Đăng ký</span>
          <div className='loginsignup-loader'></div>
        </button>
        <hr/>
        <p className='loginsignup-login'>
            <p className="loginsignup-agree">Bằng việc đăng kí, bạn đã đồng ý với QHN SHOP về <span>Điều khoản dịch vụ & Chính sách bảo mật</span></p>
            <span className="loginsignup-alreadyhasaccount">Bạn đã có tài khoản? <Link style={{textDecoration: 'none'}} to='/login'><span>Đăng nhập</span></Link></span>
        </p>
      </div>
    </div>
  )
}

export default LoginSignup