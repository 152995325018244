import React, {useEffect} from 'react'
import './ForgotPassword.css'
import { Outlet } from 'react-router-dom'
const ForgotPassword = () => {
  useEffect(() => {
    window.scrollTo({top: 0})
  }, [])
  return (
    <div className='forgotpassword'>
        <Outlet/>
    </div>
  )
}

export default ForgotPassword