import React, {useContext} from 'react'
import './SocialAuth.css'
import google_logo from '../Assets/google_icon.png'
import GoogleLogin from 'react-google-login'
import { ShopContext } from '../../Context/ShopContext'
const Google = () => {
  const {getCurrentUserComments, setDuplicatedField, setFieldValue} = useContext(ShopContext)
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  
  const changeInfo = async (image, userName, token) => {
    await fetch('https://qhn-api.onrender.com/user/editProfile', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({photo: image, userName: userName})
    })
  }
  const getUserInfo = async (token, image) => {
    console.log(image)
    return fetch('https://qhn-api.onrender.com/user/profile', {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${token}`
            }}).then(res => res.json()).then(res => {
              if(!res)
                return window.location.href = '/login'
              else
                {
                  localStorage.setItem('user-info', JSON.stringify({...res.data[0], 'photo': image, 'isSocialAccount': true}))
                  localStorage.setItem('user-comments', JSON.stringify(getCurrentUserComments(res.data[0])))
                  return window.location.href = '/'
                }
            })
  }
  const login = async (username, password, image) => {
    return fetch('https://qhn-api.onrender.com/user/login', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: username,
        password: password
      })
    }).then(res => res.json())
    .then(res => {
      if(res.status === 'success')
      {
          localStorage.setItem('session-token', res.token)
          changeInfo(image, username, res.token)
          getUserInfo(res.token, image)
      };
    })
  }

  const addGoogleAccount = async (res) => {
    const userName = `${res.profileObj.familyName} ${res.profileObj.givenName}`
    const password = '1234567890'
    const email = res.profileObj.email
    const image = res.profileObj.imageUrl
    const data = {
      userName: userName,
      email: email,
      password: password,
      passwordConfirm: password
    }
    await fetch('https://qhn-api.onrender.com/user/signup', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(res => {
        return login(userName, password, image)
    })
  }
  const onSuccess = (res) => {
    addGoogleAccount(res)
  }
  const onFailure = (res) => {
    console.log(res)
  }
  return (
      <GoogleLogin
      render={renderProps => (
        <div className='socialauth' onClick={renderProps.onClick} disabled={renderProps.disabled}>
          <img src={google_logo} alt="" />
          <span>Google</span>
        </div>
      )}
      clientId='656848930182-e3fap9apmt8o8904or0f3br4luesh3qe.apps.googleusercontent.com'
      buttonText='Google'
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
      />
  )
}

export default Google