import React, {useRef, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import './Profile.css'
import { useOutletContext } from "react-router-dom"
const Profile = () => {
  const [info] = useOutletContext()
  const [commentList, setCommentList] = useState()
  const [usn, setUsername]= useState()
  const [eml, setEmail] = useState()
  const [gend, setGender] = useState()
  const [pho, setPhone] = useState()
  const [addr, setAddress] = useState()
  const [productId, setProductId] = useState()
  const [commentId, setCommentId] = useState()
  useEffect(() => {
    setUsername(info ? info.userName : '')
    setEmail((info && info.hasOwnProperty('email') && info.email) ? info.email : '')
    setGender((info && info.hasOwnProperty('gender') && info.gender) ?  (info.gender === 'male' ? 'Nam' : 'Nữ') : '')
    setPhone((info && info.hasOwnProperty('Phone') && info.Phone) ? info.Phone : '')
    setAddress((info && info.hasOwnProperty('Address') && info.Address) ? info.Address : '')
  }, [info])
  useEffect(() => {
    const comments = JSON.parse(localStorage.getItem('user-comments'))
    setCommentList(comments ? comments : null)
  }, [])
  const username = useRef()
  const email = useRef()
  const gender = useRef()
  const phone = useRef()
  const address = useRef()
  const textarea = useRef()
  const saveData = async () => {
    const sessionToken = localStorage.getItem('session-token')
    const data = {
        userName: username.current.value,
        email: email.current.value,
        gender: gender.current.value,
        Phone: phone.current.value,
        Address: address.current.value
    }
    document.querySelector('.edit-loader').style.display = 'block'
    await fetch('https://qhn-api.onrender.com/user/editProfile', {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionToken}`
        },
        body: JSON.stringify(data)
    }).then(() => {
        return fetch('https://qhn-api.onrender.com/user/profile', {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${sessionToken}`
            }
    })})
    .then(res => res.json()).then(res => {
        document.querySelector('.edit-loader').style.display = 'none'
        localStorage.setItem('user-info', JSON.stringify(res.data[0]))
    }).then(() => window.location.reload())
  }
  const commentClick = (item) => {
    document.querySelector('.profile-comment div h5').innerText = item.title
    textarea.current.value = item.comment
    setProductId(item.productId)
    setCommentId(item._id)
  }
  const deleteComment = async (e) => {
    e.preventDefault()
    if(textarea.current.value === '')
        return
    const data = {
        userName: usn,
        commentId: commentId
    }
    await fetch(`https://qhn-api.onrender.com/product/comment/deleteCmt/${productId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('session-token')}`
          },
        body: JSON.stringify(data)
    }).then(res => res.json())
    .then(res => {
        if(res.status !== 'success')
            return
        else if(res.status === 'success')
        {
            let oldComments = JSON.parse(localStorage.getItem('user-comments'))
            const newComments = oldComments.filter(i => i._id !== commentId)
            localStorage.setItem('user-comments', JSON.stringify(newComments))
            setCommentList(newComments)
            setProductId(null)
            setCommentId(null)
            document.querySelector('.profile-comment h5').innerText = ''
            textarea.current.value = ''
        }
    })
  }
  const enableUpdate = (e) => {
    if(textarea.current.disabled && commentId)
    {
        e.currentTarget.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy" viewBox="0 0 16 16">
        <path d="M11 2H9v3h2z"/>
        <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
      </svg>`
        textarea.current.disabled = false
        return
    }
    else {
        return updateComment(e)
    }
  }
  const updateComment = async (e) => {
    e.preventDefault()
    const data = {
        userName: usn,
        comment: textarea.current.value,
        commentId: commentId
    }
    e.currentTarget.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
    </svg>`
    textarea.current.disabled = true
    await fetch(`https://qhn-api.onrender.com/product/comment/update/${productId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('session-token')}`
        },
        body: JSON.stringify(data)
    }).then(res => res.json())
    .then(res => {
        if(res.status === 'success')
        {
            let oldComments = JSON.parse(localStorage.getItem('user-comments'))
            let commentToUpdate = oldComments.find(cmt => cmt._id === commentId)
            if(commentToUpdate)
            {
                commentToUpdate.comment = textarea.current.value
                const index = oldComments.findIndex(cmt => cmt._id === commentId)
                console.log(commentToUpdate.comment)
                if(index !== -1)
                {
                    oldComments[index] = commentToUpdate
                    localStorage.setItem('user-comments', JSON.stringify(oldComments))
                    setCommentList(oldComments)
                }
            }
        }
    })
  }
  return (
    <div className="profile-page">
        <div className="profile-route">
            <h1>Thông tin cá nhân</h1>
            <span>Chào mừng trở lại! Xem những hoạt động của bạn tại đây.</span>
        </div>

        <div className="profile-maincontext">
            <div>
                <label>Tên của bạn:</label>
                <input defaultValue={usn} onInput={(e) => {
                    setUsername(e.target.value)
                }} disabled ref={username}/>
            </div>
            <div>
                <label>Email:</label>
                <input defaultValue={eml} onInput={(e) => {
                    setEmail(e.target.value)
                }} disabled ref={email}/>
            </div>
            <div>
                <label>Giới tính:</label>
                <input defaultValue={gend} disabled id='gender'/>
                <select name='gender' ref={gender}>
                    <option value='male'>Nam</option>
                    <option value='female'>Nữ</option>
                </select>
            </div>
            <div>
                <label>Số điện thoại:</label>
                <input defaultValue={pho} onInput={(e) => {
                    setPhone(e.target.value)
                }} disabled ref={phone}/>
            </div>
            <div>
                <label>Địa chỉ:</label>
                <input defaultValue={addr} onInput={(e) => {
                    setAddress(e.target.value)
                }} disabled ref={address}/>
            </div>
            <button onClick={saveData}>
                <span>Lưu</span>
                <div className='edit-loader'></div>
            </button>
        </div>

        <div className="profile-addition">
            <div className="profile-addition1">
                <h5>LỊCH SỬ MUA HÀNG</h5>
                <span>Xem những mặt hàng đã ghé qua tại đây</span>
                <div className="profile-purchaselist">
                    {
                        commentList &&
                        commentList.map((item, index) => {
                            return <div key={index} className="profile-purchaserow" onClick={() => commentClick(item)}>
                            <img src={item.image} alt=''/>
                            <div className="profile-title">
                                <h5>{item.title}</h5>
                                <h6>{`${item.category[0].toUpperCase()}${item.category.slice(1)}`}</h6>
                            </div>
                            <Link to={{pathname: '/product', search: `?_id=${item.productId}`}}>
                                Chi tiết
                            </Link>
                        </div>
                        })
                    }
                </div>
            </div>
            <div className="profile-addition2">
                <h5>ĐÁNH GIÁ CỦA BẠN</h5>
                <span>Xem đánh giá của bạn tại đây</span>
                <div className="profile-comment" id="comment">
                    <div id="text">
                    <h5>
                        {/* Empty heading */}
                    </h5>
                    <textarea disabled cols={50} rows={50} id="reviewText" ref={textarea}></textarea>
                    </div>
                    <div className="profile-comment-button">
                        <button onClick={(e) => enableUpdate(e)}>
                        <i className="bi bi-floppy"></i>
                        </button>
                        <button onClick={(e) => deleteComment(e)}>
                        <i className="bi bi-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </div>
  )
}

export default Profile