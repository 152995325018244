import React, {useState, useEffect, useContext} from 'react'
import './NewCollections.css'
import Item from '../Item/Item'
import arrow_left from '../Assets/arrow-left.png'
import arrow_right from '../Assets/arrow-right.png'
import CarouselSlide from '../CarouselSlide/CarouselSlide'
import { ShopContext } from '../../Context/ShopContext'
const NewCollections = () => {
  const {data} = useContext(ShopContext)
  const [newCollection, setNewCollection] = useState()
  useEffect(() => {
    data && (() => {
      let d = []
      for(let i = data.data.products.length - 1; i >= data.data.products.length - 8; i--)
      {
        d.push(data.data.products[i])
      }
      setNewCollection(d)
    })()
  }, [data])

  const [yAxis, setYAxis] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const nextSlide = (images) => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setYAxis(window.scrollY)
  };
  const prevSlide = (images) => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    setYAxis(window.scrollY)
  };
  useEffect(() => {
    window.scrollTo(0, yAxis);
  }, [currentIndex, yAxis])
  return (
    <div className='new-collections'>
        <h1>BỘ SƯU TẬP MỚI</h1>
        <hr/>
        <div className="new-collections-item">
            {newCollection && newCollection.map((item, i) => {
                return <Item key={i} id={item._id} name={item.name} image={item.imageCover} new_price={item.price} old_price={item.price * 1.5}/>
            })}
        </div>
        <div className="new-collections-carousel">
          {
            newCollection ? (
            <>
             <button className="new-collections-prev" type='button' onClick={() => prevSlide(newCollection)}><img src={arrow_left} alt=''/></button>
            <CarouselSlide id={newCollection[currentIndex]._id} image={newCollection[currentIndex].imageCover} carouselid={'newcollection'} new_price={newCollection[currentIndex].price} old_price={newCollection[currentIndex].price *1.5}/>
            <button className="new-collections-next" type='button' onClick={() => nextSlide(newCollection)}><img src={arrow_right} alt=''/></button>
            </>)
            : null
          }
        </div>
    </div>
  )
}

export default NewCollections