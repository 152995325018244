import React, {useState, useEffect} from 'react'
import Cropper from 'cropperjs'
import './PhotoChoosing.css'
const PhotoChoosing = () => {
  const [cropper, setCropper] = useState(null);

  const showPopup = () => {
    document.querySelector('#dark-background').style.display = 'flex';
    document.querySelector('#popup-form').style.display = 'flex';
  };

  const hidePopup = () => {
    document.querySelector('.dark-background').style.display = 'none';
  };

  const showAlert = () => {
    document.querySelector('.popup-alert-wrapper').style.display = 'flex';
  };

  const hideAlert = () => {
    document.querySelector('.popup-alert-wrapper').style.display = 'none';
  };
  const sendData = async (sessionToken) => {
    document.querySelector('.popup-alert-wrapper').style.display = 'none'
    const image = cropper.getCroppedCanvas({maxWidth: 160, maxHeight: 160}).toDataURL("image/jpg");
    document.querySelector('.userinfo-avatar img').src = image;
    document.querySelector('.userinfo-avatardiv img').src = image;
    document.querySelector('.nav-login-cart img').src = image;
    const data = {
      photo: image
    }
    await fetch('https://qhn-api.onrender.com/user/editProfile', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionToken}`
      },
      body: JSON.stringify(data)
    })
    .then(() => {
      hidePopup()
      hideAlert()
    })
    .then(async () => {
      return await fetch('https://qhn-api.onrender.com/user/profile', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${sessionToken}`
        }
      })
      .then(res => res.json())
      .then(res => {
        localStorage.setItem('user-info', JSON.stringify(res.data[0]));
      })
    });
  }
  const saveData = async () => {
    const sessionToken = localStorage.getItem('session-token');
    if (!cropper) {
      return 
    }
    return sendData(sessionToken)
  };

  useEffect(() => {
    const input = document.querySelector('#photoChoosing input');
    if (input) {
      input.addEventListener('change', function (e) {
        let files = e.currentTarget.files;
        if (files.length > 0) {
          const imgURL = window.URL.createObjectURL(files[0]);
          let img = document.querySelector('.popup-form-avatar-container img');
          img.onload = () => {
            if (cropper) {
              cropper.destroy();
            }
            setCropper(new Cropper(img, {
              aspectRatio: 0,
              viewMode: 0,
            }));
            showPopup();
          };
          img.src = imgURL;
        }
      });
    }
  }, [cropper]);
  return (
    <div className="dark-background" id="dark-background">
    <div className="popup-form" id="popup-form">
        <div className='popup-form-header'>
          <p>Chỉnh sửa ảnh của bạn</p>
        </div>
        <div className='popup-form-avatar-container'>
        <img id="yourAvatar" alt=''/>
        </div>
        {/*<label id="chooseAvatar">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera" viewBox="0 0 16 16">
        <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z"/>
        <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"/>
        </svg>
        <div>
        <input type="file" />
        </div>
        </label>*/}

        <div className='popup-form-btn'>
            <button id="cancel" onClick={hidePopup}>Hủy</button>
            <button id="saveChange" onClick={showAlert}>Lưu</button>
        </div>
    </div>
    <div className='popup-alert-wrapper'>
    <div className="popup-alert" id="popup-alert">
        <span className="alertTitle" id="alertTitle">Lưu thông tin</span>
        <span className="alertText" id="alertText">Bạn chắc chắn muốn lưu thông tin?</span>
        <div className="optionAlert" id="optionAlert">
            <button className="cancelAlert" id="cancelAlert" onClick={hideAlert}>Hủy</button>
            <button className="saveChangeAlert" id="saveChangeAlert" onClick={saveData}>Lưu</button>
        </div>
    </div>
    <div className="popup-alert" id="popup-logout-alert" style={{display: 'none'}}>
        <span className="alertTitle" id="logoutAlertTitle">Ready to sign out</span>
        <span className="alertText" id="logoutAlertText">Are you sure to sign out?</span>
        <div className="optionAlert">
            <button className="cancelAlert" id="cancelLogout">Cancel</button>
            <button className="saveChangeAlert" id="logOut">Yes</button>
        </div>
    </div>
    </div>
    </div>
  )
}

export default PhotoChoosing