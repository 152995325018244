import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Breadcrums from '../../Components/Breadcrums/Breadcrums'
import ProductDisplay from '../../Components/ProductDisplay/ProductDisplay'
import DescriptionBox from '../../Components/DescriptionBox/DescriptionBox'
import RelatedProducts from '../../Components/RelatedProducts/RelatedProducts'


const Product = () => {
  const [product, setProduct] = useState()
  const location = useLocation()
  const query = location.search
  useEffect(() => {
    (async () => {
      try{
        const promise = await fetch(`https://qhn-api.onrender.com/product${query}`)
        const response = await promise.json()
        setProduct(response)
      }
      catch(e){
        console.log(e)
      }
    })()
  }, [query])
  return (
    <div>
      {
        product && (
          <>
          <Breadcrums product={product.data.products[0]}/>
          <ProductDisplay product={product.data.products[0]}/>
          <DescriptionBox product={product.data.products[0]}/>
          <RelatedProducts classify={product.data.products[0].classify}/>
          </>
        )
      }
    </div>
  )
}

export default Product