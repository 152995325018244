import React, {useContext, useState} from 'react'
import { ShopContext } from '../../Context/ShopContext'
import './SocialAuth.css'
const Validate = () => {
  const {duplicatedField} = useContext(ShopContext)
  const [nextSlide, setNextSlide] = useState(true)
  return (
    <div className='validate'>
        <img src='https://graph.facebook.com/1179369393222242/picture'/>
        {
            nextSlide ? 
            (<>
            <h2>Nguyễn Nguyên</h2>
            <h3>{duplicatedField} đã được sử dụng</h3>
            <div>
            <button>Đồng bộ hóa dữ liệu</button>
            <button onClick={() => setNextSlide(false)}>Thay đổi tên người dùng khác</button>
            </div></>)
            : (<div><form>
                <label>
                    <span>Tên người dùng cũ</span>
                    <input placeholder='Tên người dùng cũ' disabled className='validate-disabled'/>
                </label>
                <label>
                    <span>Tên người dùng mới</span>
                    <input placeholder='Tên người dùng mới'/>
                </label>
                <button>Xác nhận</button>
            </form></div>)
        }   
    </div>
  )
}

export default Validate