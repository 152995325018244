import React from 'react'
import './Cart.css'
import CartItem from '../../Components/CartItem/CartItem'
const Cart = () => {
  return (
    <div>
      <CartItem/>
    </div>
  )
}

export default Cart