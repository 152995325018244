import React, { useState, useContext, useEffect } from 'react'
import '../../Components/Item/Item.css'
import {ShopContext} from '../../Context/ShopContext'
import './ShopCategory.css'
import dropdown_icon from '../../Components/Assets/dropdown_icon.png'
import Item from '../../Components/Item/Item'

const ShopCategory = (props) => {
  const {data} = useContext(ShopContext)
  const [showing, setShowing] = useState(false)
  const [showProducts, setShowProducts] = useState(null)
    useEffect(() => {
      if(data)
      {
        const sh = [...data.data.products.filter(i => i.classify === props.classify)]
        setShowProducts(sh.slice(0, 12))
      }
    }, [data, props.classify])
  const showFull = (e) => {
    if(data)
    {
      document.querySelector('.shopcategory-indexSort p span').textContent = data.data.products.filter(i => i.classify === props.classify).length
      setShowProducts([...data.data.products.filter(i => i.classify === props.classify)])
    }
    setShowing(!showing)
    e.currentTarget.style.display = 'none'
    
  }
  return (
    <div className='shopcategory'>
      <img className="shopcategory-banner" src={props.banner} alt={`${props.category}_banner.png`}/>
      <div className="shopcategory-indexSort">
        <p>
          Xem <span>1-12</span> trong {data && [...data.data.products.filter(i => i.classify === props.classify)].length} sản phẩm
        </p>
        <div className="shopcategory-sort">
          Sắp xếp theo <img src={dropdown_icon} alt='dropdown_icon.png'/>
        </div>
      </div>
      <div className="shopcategory-products">
        { 
        (data && showProducts) &&
        showProducts.map((item, i) => {
            return <Item key={i} id={item._id} name={item.name} image={item.imageCover} new_price={item.price} old_price={item.price * 1.5}/>
        }) 
      }
      </div>
      {
        !showing &&
        (
          <div className="shopcategory-loadmore" onClick={(e) => showFull(e)}>
          Xem Thêm
        </div>
        )
      }
    </div>
  )
}

export default ShopCategory